import { createSlice } from '@reduxjs/toolkit';

import api from '../api';
import { logout } from './authorizationSlice';
import { ApiError } from '../constants';
import transformOrganizationsFromApi from '../adapters/organizations';

const initialState = {
  dataLoading: true,
  funeralHomes: [],
};

export const funeralHomesSlice = createSlice({
  name: 'funeralHomes',
  initialState,
  reducers: {
    setFuneralHomes: (state, action) => {
      state.funeralHomes = action.payload;
    },
    setDataLoadingInProcess: (state, action) => {
      state.dataLoading = action.payload;
    },
  },
});

export const {
  setFuneralHomes,
  setDataLoadingInProcess,
} = funeralHomesSlice.actions;

export default funeralHomesSlice.reducer;

export const loadFuneralHomesData = () => async (dispatch) => {
  try {
    dispatch(setDataLoadingInProcess(true));
    const response = await api.get('/organizations/funeral_homes');

    if (response.data) {
      const funeralHomes = transformOrganizationsFromApi(response.data);

      dispatch(setFuneralHomes(funeralHomes));
    }
    dispatch(setDataLoadingInProcess(false));
  } catch (error) {
    if (error.response?.data?.status === ApiError.UNAUTHORIZED) {
      dispatch(logout());
    } else {
      console.error(error);
      dispatch(setDataLoadingInProcess(false));
    }
  }
};

export const getFuneralHomeById = (homeId) => (dispatch, getState) => {
  const {funeralHomes} = getState().funeralHomes;
  return funeralHomes.find((item) => item.id === homeId);
};
