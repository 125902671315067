import {useCallback, useState} from 'react';

export default function usePopup() {
  const [isPopupShown, setIsPopupShown] = useState(false);

  const showPopup = useCallback(() => {
    setIsPopupShown(true);
  }, [setIsPopupShown]);

  const hidePopup = useCallback(() => {
    setIsPopupShown(false);
  }, [setIsPopupShown]);

  const togglePopup = useCallback(() => setIsPopupShown((prevState) => !prevState), [isPopupShown]);

  return {
    hidePopup,
    isPopupShown,
    showPopup,
    togglePopup,
  };
}
