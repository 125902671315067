import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';

import './loader.scss';

const DEBOUNCE_INTERVAL = 300;

const Loader = ({
  className,
  noDebounce,
  noText,
  status,
}) => {
  const [showComponent, setShowComponent] = useState(noDebounce);

  useEffect(() => {
    const timer = setTimeout(() => setShowComponent(true), DEBOUNCE_INTERVAL);
    return () => clearTimeout(timer);
  }, []);

  return showComponent && (
    <div className={classNames('loader', className)}>
      <div className='loader__wrapper'>
        <Spinner />
        {noText || <p className='loader__text'>{status || 'Please wait'}</p>}
      </div>
    </div>
  );
};

Loader.defaultProps = {
  className: '',
  noDebounce: false,
  noText: false,
  status: '',
};

Loader.propTypes = {
  className: PropTypes.string,
  noDebounce: PropTypes.bool,
  noText: PropTypes.bool,
  status: PropTypes.string,
};

export default Loader;
