import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

const Input = React.forwardRef(({
  autoFocus,
  children,
  className,
  disabled,
  errorMsg,
  id,
  invalid,
  isFlattened,
  label,
  mask,
  maskPlaceholder,
  maxLength,
  onBlur,
  onChange,
  onClick,
  onFocus,
  placeholder,
  readOnly,
  type,
  value,
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleBlur = (evt) => {
    setIsFocused(false);
    onBlur(evt);
  };

  const handleFocus = () => {
    setIsFocused(true);
    onFocus();
  };

  return (
    <div className={classNames('input', className, invalid && 'input_error', disabled && 'input_disabled', isFlattened && 'input_flattened')} ref={ref}>
      {label && <label htmlFor={id} className={classNames('input__label', (isFocused || value?.length || placeholder) && 'input__label_raised')}>{label}</label>}
      <InputMask
        autoFocus={autoFocus}
        id={id}
        className="input__field"
        disabled={disabled}
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        maxLength={maxLength}
        onChange={onChange}
        onClick={onClick}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        value={value}
      />
      {children}
      {invalid && errorMsg && !isFocused && <div className='input__error-msg'>{errorMsg}</div>}
    </div>
  );
});

Input.defaultProps = {
  className: '',
  errorMsg: '',
  invalid: false,
  label: '',
  mask: '',
  maskPlaceholder: '',
  maxLength: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onClick: () => {},
  placeholder: '',
  readOnly: false,
  type: 'text',
  value: '',
};

Input.propTypes = {
  className: PropTypes.string,
  errorMsg: PropTypes.string,
  id: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  mask: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  maxLength: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Input;
