import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {ReactComponent as IconArrow} from '../../../../assets/icons/icon-dropdown.svg';
import {ReactComponent as IconDoubleArrow} from '../../../../assets/icons/icon-double-arrow.svg';
import {
  Button,
} from '../../../ui-components';

import './pagination.scss';

const Pagination = ({
  className,
  isFirstPage,
  isLastPage,
  onFirstPageButtonClick,
  onLastPageButtonClick,
  onPreviousPageButtonClick,
  onNextPageButtonClick,
  startListCount,
  endListCount,
  totalArrangementsCount,
}) => (
  <div className={classNames('pagination', className)}>
    <div className='pagination__numbers'>
      {startListCount}-{endListCount}&nbsp;of&nbsp;{totalArrangementsCount}
    </div>
    <div className='pagination__buttons'>
      <div className='pagination__buttons-pair'>
        <Button
          classNameCustom='pagination__button'
          ariaLabel='To first page'
          disabled={isFirstPage}
          onClick={onFirstPageButtonClick}
          icon={<IconDoubleArrow />}
        />
        <Button
          classNameCustom='pagination__button pagination__button_previous'
          ariaLabel='To previous page'
          disabled={isFirstPage}
          icon={<IconArrow />}
          onClick={onPreviousPageButtonClick}
        />
      </div>
      <div className='pagination__buttons-pair'>
        <Button
          classNameCustom='pagination__button pagination__button_next'
          ariaLabel='To next page'
          disabled={isLastPage}
          icon={<IconArrow />}
          onClick={onNextPageButtonClick}
        />
        <Button
          classNameCustom='pagination__button pagination__button_last'
          ariaLabel='To last page'
          disabled={isLastPage}
          icon={<IconDoubleArrow />}
          onClick={onLastPageButtonClick}
        />
      </div>
    </div>
  </div>
);

Pagination.defaultProps = {
  className: '',
};

Pagination.propTypes = {
  className: PropTypes.string,
  isFirstPage: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  onFirstPageButtonClick: PropTypes.func.isRequired,
  onLastPageButtonClick: PropTypes.func.isRequired,
  onPreviousPageButtonClick: PropTypes.func.isRequired,
  onNextPageButtonClick: PropTypes.func.isRequired,
  startListCount: PropTypes.number.isRequired,
  endListCount: PropTypes.number.isRequired,
  totalArrangementsCount: PropTypes.number.isRequired,
};

export default Pagination;
