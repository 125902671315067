import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../../../ui-components';

import { ArrangementTab } from '../../../../constants';

import './tabs.scss';

const Tabs = ({
  className,
  activeTab,
  handleDocumentsTabClick,
  handleSummaryTabClick,
}) => (
  <div className={classNames('tabs', className)}>
    <div className='tabs__wrapper'>
      <Button
        className={classNames('tabs__item', activeTab === ArrangementTab.SUMMARY && 'tabs__item_active')}
        textClassName='tabs__item-text'
        text={ArrangementTab.SUMMARY}
        onClick={handleSummaryTabClick}
      />
      <Button
        className={classNames('tabs__item', activeTab === ArrangementTab.DOCUMENTS && 'tabs__item_active')}
        textClassName='tabs__item-text'
        text={ArrangementTab.DOCUMENTS}
        onClick={handleDocumentsTabClick}
      />
    </div>
  </div>
);

Tabs.defaultProps = {
  className: '',
};

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleDocumentsTabClick: PropTypes.func.isRequired,
  handleSummaryTabClick: PropTypes.func.isRequired,
};

export default Tabs;
