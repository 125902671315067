import {useCallback, useState} from 'react';

export default function useUnmountAnimation(unmountHandler = () => {}) {
  const [isUnmounting, setIsUnmounting] = useState(false);

  const unmountComponent = useCallback(() => {
    setIsUnmounting(true);
  }, [setIsUnmounting]);

  const handleAnimationEnd = useCallback(() => {
    if (isUnmounting) {
      unmountHandler();
    }
  }, [isUnmounting]);

  return [unmountComponent, handleAnimationEnd, isUnmounting];
}
