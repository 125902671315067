import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../buttons';
import { Loader } from '../../../common-components';

const getFormattedTextValue = (text, searchStr) => {
  const boldTextIndex = text.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
    ? text.toLowerCase().indexOf(searchStr.toLowerCase())
    : 0;
  const boldTextEndIndex = text.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
    ? boldTextIndex + searchStr.length
    : 0;
  const beginText = text.slice(0, boldTextIndex);
  const boldText = text.slice(boldTextIndex, boldTextEndIndex);
  const endText = text.slice(boldTextEndIndex);

  return (
    <>
      {beginText ? <span className="typeahead__item-label">{beginText}</span> : null}
      {boldText ? (
        <span className="typeahead__item-label typeahead__item-label_bold">{boldText}</span>
      ) : null}
      {endText ? <span className="typeahead__item-label">{endText}</span> : null}
    </>
  );
};

const TypeaheadDropdown = ({
  handleItemClick,
  listItems,
  listLoading,
  searchStr,
}) => (
  <div className='typeahead__dropdown'>
    {
      listLoading
        ? <Loader noText />
        : (
          <div className='typeahead__list'>
            {listItems.map((item) => (
              <Button
                className='typeahead__item'
                id={`typeaheadOption-${item.id}`}
                disabled={item.disabled}
                key={item.id}
                onClick={() => handleItemClick(item)}
              >
                {searchStr ? getFormattedTextValue(item.value, searchStr) : item.value}
              </Button>
            ))}
          </div>
        )
    }
  </div>
);

TypeaheadDropdown.defaultProps = {
  listItems: [],
  searchStr: '',
};

TypeaheadDropdown.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  searchStr: PropTypes.string,
};

export default TypeaheadDropdown;
