import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Button,
  Input,
} from '../../../ui-components';
import {ReactComponent as IconSearch} from '../../../../assets/icons/icon-search.svg';

import { KeyCode } from '../../../../constants';
import useKeyDown from '../../../../hooks/useKeyDown';
import { setSearchFilter } from '../../../../store/arrangementsSlice';

import './search.scss';

const Search = ({ className}) => {
  const {searchFilter} = useSelector((state) => state.arrangements);
  const [searchValue, setSearchValue] = useState(searchFilter);
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);

  const startSearch = () => {
    dispatch(setSearchFilter(searchValue));
  };

  useEffect(() => {
    if (!searchValue && searchFilter) {
      startSearch();
    }
  }, [searchValue]);

  useKeyDown(KeyCode.ENTER, startSearch, searchInputRef);

  return (
    <div className={classNames('search', className)}>
      <Input
        className='input_with-icon input_no-border'
        id='decedentSearch'
        placeholder='Decedent name'
        onChange={(evt) => setSearchValue(evt.target.value)}
        value={searchValue}
        ref={searchInputRef}
      >
        <Button
          classNameCustom='input__icon-button input__icon-button_big'
          ariaLabel='Search decedent'
          icon={<IconSearch />}
          onClick={startSearch}
        />
      </Input>
    </div>
  );
};

Search.defaultProps = {
  className: '',
};

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;
