import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Input } from '../../../ui-components';
import { Loader} from '../../../common-components';
import Copyright from '../copyright';
import ModuleHeader from '../module-header';

import {
  InputType,
} from '../../../../constants';
import useForm from '../../../../hooks/useForm';
import {transformUserForSignIn} from '../../../../adapters/users';
import { requireAuthorization, setAuthorizationError } from '../../../../store/authorizationSlice';

import './sign-in.scss';

const formInputs = {
  userEmail: {
    value: '',
    error: '',
    isRequired: true,
    label: 'E-mail address',
    type: InputType.EMAIL,
  },
  userPassword: {
    value: '',
    error: '',
    isRequired: true,
    label: 'Password',
    type: InputType.PASSWORD,
  },
};

const SignIn = ({
  handleForgotPasswordButtonClick,
  handleSignUpButtonClick,
}) => {
  const { authorizationError, authorizationInProcess } = useSelector((state) => state.authorization);
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(setAuthorizationError('')), []);

  const submitForm = (formData) => {
    const data = transformUserForSignIn(formData);
    dispatch(requireAuthorization(data));
  };

  const {
    checkFieldError,
    formFields,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(formInputs, submitForm);

  return (
    <div className='sign-in'>
      {authorizationInProcess ? <Loader noDebounce/> : (
        <div className='sign-in__wrapper'>
          <ModuleHeader
            error={authorizationError}
            text='Please sign in'
            title='Funeral Director Workstation'
          />
          <form className='sign-in__form'>
            {
              Object.entries(formFields).map(([id, field]) => (
                <Input
                  key={id}
                  id={id}
                  errorMsg={field.error}
                  invalid={!!field.error}
                  label={field.label}
                  onBlur={checkFieldError}
                  onChange={handleChange}
                  type={field.type}
                  value={field.value}
                />
              ))
            }
            <Button
              classNameCustom='sign-in__reset'
              disabled
              onClick={handleForgotPasswordButtonClick}
              text='Forgot your password?'
            />
            <Button
              classNameCustom='sign-in__submit-btn button_rectangle button_bg'
              disabled={!isFormValid}
              onClick={handleSubmit}
              text='Sign in'
            />
            <Button
              classNameCustom='sign-in__signup-btn button_rectangle button_border'
              onClick={handleSignUpButtonClick}
              text='Sign up'
            />
          </form>
        </div>
      )}
      <Copyright />
    </div>
  );
};

SignIn.propTypes = {
  handleForgotPasswordButtonClick: PropTypes.func.isRequired,
  handleSignUpButtonClick: PropTypes.func.isRequired,
};

export default SignIn;
