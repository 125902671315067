import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import { getDigits } from '../../../utils';
import api from '../../../api';
import { authorize } from '../../../store/authorizationSlice';

const SMS_CODE_LENGTH = 4;
const CHANGE_SCREEN_TIME_DELAY = 1000;

export default function usePhoneVerification(phone) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loaderStatus, setLoaderStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const sendCodeRequest = () => {
      setLoading(true);
      api.post('/users/verify/phone', {phone, code})
        .then((response) => {
          if (response.data) {
            setLoaderStatus('Your mobile phone number is verified');
            setTimeout(() => {
              dispatch(authorize());
            }, CHANGE_SCREEN_TIME_DELAY);
          }
        })
        .catch((err) => {
          setError(err.response.data.userMessage);
          setLoading(false);
        });
    };

    if (getDigits(code).length === SMS_CODE_LENGTH) {
      sendCodeRequest();
    }
  }, [code]);

  const handleCodeChange = ({target: {value}}) => {
    setCode(value);
  };

  const handleVerifyLaterButtonClick = () => dispatch(authorize());

  return {
    code,
    error,
    handleCodeChange,
    handleVerifyLaterButtonClick,
    loading,
    loaderStatus,
  };
}
