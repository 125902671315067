import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../../../../../ui-components';
import {
  DataList,
} from '../../../../../common-components';

import {
  formatAsUsdDollars,
  formatDateToUSLocal,
  formatTimeMinutesToUSLocal,
  sumServicesAmount,
} from '../../../../../../utils';
import { isForestHillInstallation } from '../../../../../../constants';

const ArrangementSidebarDataSummary = ({
  arrangementData,
  showPopup,
}) => {
  const isArrangementCanBeCanceled = arrangementData?.status === 'New' && arrangementData?.paymentStatus === 'Unpaid';
  const arrangementDate = formatDateToUSLocal(arrangementData.date);
  const arrangementTime = formatTimeMinutesToUSLocal(arrangementData.time);
  const total = formatAsUsdDollars(sumServicesAmount(arrangementData.services));
  const returnValue = arrangementData.returnDate ? formatDateToUSLocal(arrangementData.returnDate) : '-';
  const deathDate = arrangementData.decedentDeathDate ? formatDateToUSLocal(arrangementData.decedentDeathDate) : '-';
  const funeralHome = arrangementData.funeralHomeName || '';

  const datalistGeneralValues = [
    {key: 'Arrangement Date', value: arrangementDate, isValueBold: true},
    {key: 'Time', value: arrangementTime, isValueBold: true},
    {key: 'Container Type', value: arrangementData.containerType, isValueBold: true},
    {key: 'Payment', value: arrangementData.paymentMethod || 'Paid later', isValueBold: true},
    {key: 'Total Cost', value: total, isValueBold: true},
    {
      key: 'Payment Status',
      value: arrangementData.paymentStatus,
      isValueBold: true,
      isStatus: true,
    },
  ];

  const decedentValues = [
    {key: 'First Name:', value: arrangementData.decedentFirstName},
    {key: 'Middle Name:', value: arrangementData.decedentMiddleName || '-'},
    {key: 'Last Name:', value: arrangementData.decedentLastName},
    {key: 'Date of Death:', value: deathDate},
    {key: 'Age:', value: arrangementData.decedentAge},
    {key: 'Gender:', value: arrangementData.decedentSex || '-'},
    {key: 'Weight, lb:', value: arrangementData.decedentWeight},
    {key: 'Place of Death:', value: arrangementData.decedentDeathPlace},
    {key: 'Manner of Death:', value: arrangementData.decedentDeathCause},
  ];

  const funeralHomeValues = [
    {key: 'Funeral Home:', value: funeralHome},
  ];

  const paymentlistArrangementValues = [
    {key: 'Payment Method:', value: arrangementData.paymentMethod || 'Paid later'},
    {key: 'Total Cost:', value: total},
    {
      key: 'Payment Status:',
      value: arrangementData.paymentStatus,
      isValueBold: false,
    },
  ];

  const cremationValues = [
    {key: 'Container Type:', value: arrangementData.containerType},
    {key: isForestHillInstallation ? 'Universal ID #:' : 'UIS Bracelet #:', value: arrangementData.decedentUIS || '-'},
    {key: 'Other:', value: arrangementData.otherRemarks || '-'},
  ];

  const returnValues = [
    {key: 'Return in:', value: arrangementData.returnIn || '-'},
    {key: 'Notify by:', value: arrangementData.notification || '-'},
    {key: 'Need by:', value: arrangementData.returnNeedBy || '-'},
    {key: 'Return Date:', value: returnValue},
    {key: 'Urgency:', value: arrangementData.urgency},
  ];

  const additionalValues = [
    {key: 'Special Instructions:', value: arrangementData.specialInstruction || '-'},
  ];

  return (
    <div className={classNames('arrangement-sidebar__data arrangement-sidebar__data_summary', isArrangementCanBeCanceled && 'arrangement-sidebar__data_cancelable')}>
      <div className="arrangement-sidebar__data-main">
        <DataList
          id='arrangementSidebarDatalistGeneral'
          className='arrangement-sidebar__data-list  data-list_bg  data-list_desktop-row'
          pairs={datalistGeneralValues}
        />
        <div className='arrangement-sidebar__data-block'>
          <p className='arrangement-sidebar__data-header'>Funeral Home</p>
          <DataList
            id='arrangementSidebarTopDatalistArrangement'
            className='arrangement-sidebar__data-list  data-list_align-left-desktop'
            pairs={funeralHomeValues}
          />
        </div>
        <div className='arrangement-sidebar__data-block'>
          <p className='arrangement-sidebar__data-header'>Payment</p>
          <DataList
            id='arrangementSidebarTopDatalistArrangement'
            className='arrangement-sidebar__data-list  data-list_align-left-desktop'
            pairs={paymentlistArrangementValues}
          />
        </div>
        <div className='arrangement-sidebar__data-block'>
          <p className='arrangement-sidebar__data-header'>Decedent Personal Info</p>
          <DataList
            id='arrangementSidebarDecedentInfo'
            className='arrangement-sidebar__data-list  data-list_align-left-desktop'
            pairs={decedentValues}
          />
        </div>
        <div className='arrangement-sidebar__data-block'>
          <p className='arrangement-sidebar__data-header'>Cremation Data</p>
          <DataList
            id='arrangementSidebarAdditionalCremationData'
            className='arrangement-sidebar__data-list  data-list_align-left-desktop'
            pairs={cremationValues}
          />
        </div>
        <div className='arrangement-sidebar__data-block'>
          <p className='arrangement-sidebar__data-header'>Return</p>
          <DataList
            id='arrangementSidebarAdditionalCremationData'
            className='arrangement-sidebar__data-list  data-list_align-left-desktop'
            pairs={returnValues}
          />
        </div>
        <div className='arrangement-sidebar__data-block'>
          <p className='arrangement-sidebar__data-header'>Additional Information</p>
          <DataList
            id='arrangementSidebarAdditionalCremationData'
            className='arrangement-sidebar__data-list  data-list_align-left-desktop'
            pairs={additionalValues}
          />
        </div>
      </div>

      <div className='arrangement-sidebar__data-block'>
        <p className='arrangement-sidebar__data-header arrangement-sidebar__data-header_cost'>Added Services</p>
        <div className='arrangement-sidebar__cost-table'>
          <div className='arrangement-sidebar__cost-table-row arrangement-sidebar__cost-table-row_header'>
            <p className='arrangement-sidebar__cost-table-cell'>Name</p>
            <p className='arrangement-sidebar__cost-table-cell'>Cost</p>
          </div>
          <div className='arrangement-sidebar__cost-table-body'>
            {arrangementData.services.map((item) => (
              <div className='arrangement-sidebar__cost-table-row' key={item.service.id}>
                <p className='arrangement-sidebar__cost-table-cell'>{item.service.name}</p>
                <p className='arrangement-sidebar__cost-table-cell'>{formatAsUsdDollars(item.service.price * item.count)}</p>
              </div>
            ))}
          </div>
          <div className='arrangement-sidebar__cost-table-row arrangement-sidebar__cost-table-row_footer'>
            <p className='arrangement-sidebar__cost-table-cell'>Total</p>
            <p className='arrangement-sidebar__cost-table-cell'>{total}</p>
          </div>
        </div>
      </div>
      {isArrangementCanBeCanceled && (
        <Button
          classNameCustom='arrangement-sidebar__cancel-button  button_rectangle'
          text='Cancel arrangement'
          onClick={showPopup}
        />
      )}
    </div>
  );
};

ArrangementSidebarDataSummary.defaultProps = {
  arrangementData: null,
};

ArrangementSidebarDataSummary.propTypes = {
  arrangementData: PropTypes.object,
};

export default ArrangementSidebarDataSummary;
