import React from 'react';
import PropTypes from 'prop-types';

import LogoText from '../logo-text';

import { IS_LOGO_TEXT_SEPARATED, LogoLocation } from '../../../constants';
import { getCemeteryLogoProps } from '../../../utils';

import './cemetery-logo.scss';

const CemeteryLogo = ({ imgClasses, location, onLoad }) => {
  const {
    logoClasses,
    logoMobileSrc,
    logoDesktopSrc,
    logoWidth,
    logoHeight,
  } = getCemeteryLogoProps(location, IS_LOGO_TEXT_SEPARATED);

  return (
    <div className={`cemetery-logo cemetery-logo_${location} ${IS_LOGO_TEXT_SEPARATED && ('cemetery-logo_separated')}`}>
      <picture>
        <source srcSet={logoDesktopSrc} media='(min-width: 1024px)' />
        <img
          className={`${imgClasses} ${logoClasses}`}
          alt='Cemetery logo'
          height={logoHeight}
          width={logoWidth}
          src={logoMobileSrc}
          onLoad={onLoad}
        />
      </picture>
      {IS_LOGO_TEXT_SEPARATED && (
        <LogoText
          location={location}
          title={window.APP_CEMETERY_LOGO_TITLE}
          subtitle={window.APP_CEMETERY_LOGO_SUBTITLE}
        />
      )}
    </div>

  );
};

CemeteryLogo.defaultProps = {
  imgClasses: '',
  onLoad: undefined,
};

CemeteryLogo.propTypes = {
  imgClasses: PropTypes.string,
  location: PropTypes.oneOf(Object.values(LogoLocation)).isRequired,
  onLoad: PropTypes.func,
};

export default CemeteryLogo;
