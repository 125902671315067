import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Button,
  ButtonRouterLink,
  Checkbox,
  Input,
  InputPasswordIcon,
  Typeahead,
} from '../../../ui-components';
import { Error, Loader } from '../../../common-components';
import ModuleHeader from '../module-header';
import VerifyPhoneScreen from '../verify-phone-screen/verify-phone-screen';

import {
  AppRoute,
  InputType,
} from '../../../../constants';
import useSignUpForm from '../../hooks/useSignUpForm';

import './sign-up.scss';

const SignUp = ({ handleSignInButtonClick }) => {
  const {
    checkFieldError,
    formFields,
    handleChange,
    handleSelectChange,
    handleSubmit,
    alreadyExistError,
    isDataLoading,
    isFormValid,
    isAgreementAccepted,
    isVerifyPhoneScreen,
    toggleIsAgreementAccepted,
    signUpError,
  } = useSignUpForm();
  const {funeralHomes, dataLoading} = useSelector((state) => state.funeralHomes);

  // visually splits form into 3 blocks
  const personalFormBlock = Object.entries(formFields).slice(0, 4);
  const contactsFormBlock = Object.entries(formFields).slice(4, 6);
  const passwordsFormBlock = Object.entries(formFields).slice(6);

  const renderInput = ([id, field]) => {
    if (field.type === InputType.TYPEAHEAD) {
      return (
        <Typeahead
          key={id}
          id={id}
          defaultValue={field.value}
          label={field.label}
          listItems={funeralHomes}
          listLoading={dataLoading}
          handleValueSet={(value) => handleSelectChange(id, value)}
        />
      );
    }
    return (
      <Input
        key={id}
        id={id}
        errorMsg={field.error}
        invalid={!!field.error}
        label={field.label}
        mask={field.mask || ''}
        maxLength={field.maxLength || ''}
        onBlur={checkFieldError}
        onChange={handleChange}
        value={field.value}
      />
    );
  };

  if (isVerifyPhoneScreen) {
    return <VerifyPhoneScreen phone={formFields.phone.value} />;
  }

  return (
    <div className={classNames('sign-up', isVerifyPhoneScreen && 'sign-up_verifying')}>
      {isDataLoading ? <Loader noDebounce/> : (
        <div className='sign-up__wrapper'>
          <ModuleHeader
            error={signUpError}
            title='Sign Up'
          />
          <form className='sign-up__form'>
            {
              <div className='sign-up__inputs-block  sign-up__inputs-block_personal'>
                {personalFormBlock.map(([id, field]) => renderInput([id, field]))}
              </div>
            }
            {alreadyExistError && <Error className='error_bg' text={alreadyExistError}/>}
            {
              <div className={classNames('sign-up__inputs-block sign-up__inputs-block_contacts', alreadyExistError && 'sign-up__inputs-block_error')}>
                {contactsFormBlock.map(([id, field]) => renderInput([id, field]))}
              </div>
            }
            <div className='sign-up__inputs-block'>
              {
                passwordsFormBlock.map(([id, field]) => (
                  <InputPasswordIcon
                    key={id}
                    id={id}
                    errorMsg={field.error}
                    invalid={!!field.error}
                    label={field.label}
                    onBlur={checkFieldError}
                    onChange={handleChange}
                    value={field.value}
                  />
                ))
              }
            </div>
            <Checkbox
              className='sign-up__checkbox'
              id='signUpFormCheckbox'
              label='I agree with'
              onChange={toggleIsAgreementAccepted}
              checked={isAgreementAccepted}
            >
              <span>&nbsp;</span>
              <ButtonRouterLink className='sign-up__link' to={AppRoute.TERMS} text='Terms of Use' target='_blank'/>
              <span>&nbsp;&amp;&nbsp;</span>
              <ButtonRouterLink className='sign-up__link' to={AppRoute.POLICY} text='Privacy Policy' target='_blank'/>
            </Checkbox>
            <div className='sign-up__buttons'>
              <Button
                classNameCustom='sign-up__submit-btn button_rectangle button_bg'
                disabled={!isFormValid || !isAgreementAccepted}
                onClick={handleSubmit}
                text='Sign up'
              />
              <Button
                classNameCustom='sign-up__signin-btn button_rectangle button_border'
                onClick={handleSignInButtonClick}
                text='Back to Sign in'
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

SignUp.propTypes = {
  handleSignInButtonClick: PropTypes.func.isRequired,
};

export default SignUp;
