import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './multiline-input.scss';

function MultilineInput({
  className,
  id,
  label,
  maxLength,
  value,
  textError,
  rows,
  isDisabled,
  isValid,
  onBlur,
  onChange,
}) {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (evt) => {
    setIsFocused(false);
    onBlur(evt);
  };

  return (
    <div className={classNames('multiline-input', className)}>
      <div
        className={classNames(
          'multiline-input__container',
          isFocused && 'multiline-input__container_focused',
          isDisabled && 'multiline-input__container_disabled',
          !isValid && 'multiline-input__container_invalid',
        )}
      >
        <textarea
          id={id}
          className="multiline-input__input"
          maxLength={maxLength}
          rows={rows}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={isDisabled}
          onChange={onChange}
          value={value}
        />

        <label
          className={classNames(
            'multiline-input__label',
            (isFocused || value?.length) && 'multiline-input__label_raised',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      </div>

      {!isValid && !isDisabled ? <span className="multiline-input__error">{textError}</span> : null}
    </div>
  );
}

MultilineInput.defaultProps = {
  className: '',
  isDisabled: false,
  isValid: true,
  label: '',
  maxLength: '',
  textError: '',
  rows: 6,
  value: '',
};

MultilineInput.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  textError: PropTypes.string,
  value: PropTypes.string,
};

export default MultilineInput;
