import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = React.forwardRef((props, ref) => {
  const {
    ariaLabel,
    icon,
    children,
    className,
    classNameCustom,
    disabled,
    onClick,
    text,
    textClassName,
  } = props;

  return (
    <button
      aria-label={ariaLabel}
      className={classNames(className, classNameCustom && classNameCustom)}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      type='button'
    >
      {icon}
      {text && <span className={textClassName || 'button__text'}>{text}</span>}
      {children}
    </button>
  );
});

Button.defaultProps = {
  ariaLabel: '',
  className: 'button',
  classNameCustom: '',
  icon: null,
  disabled: false,
  onClick: () => {},
  text: '',
  textClassName: '',
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  classNameCustom: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textClassName: PropTypes.string,
};

export default Button;
