import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbar } from 'react-scrollbars-custom';

import './custom-scrollbar.scss';

const DESKTOP_WIDTH = 1280;
const LISTENER_DEBOUNCE_INTERVAL = 200;

export const ScrollbarContext = React.createContext(null);

function CustomScrollbar({
  className,
  height,
  width,
  children,
}) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < DESKTOP_WIDTH);
  const [isMounted, setIsMounted] = React.useState(false);
  const [isNoScroll, setIsNoScroll] = React.useState(false);

  const onResize = () => {
    if (isMounted) {
      setTimeout(() => {
        if (window.innerWidth < DESKTOP_WIDTH && !isMobile) {
          setIsMobile(true);
        }

        if (window.innerWidth >= DESKTOP_WIDTH && isMobile) {
          setIsMobile(false);
        }
      }, LISTENER_DEBOUNCE_INTERVAL);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <ScrollbarContext.Provider value={setIsNoScroll}>
      <Scrollbar
        className={classNames('custom-scrollbar', className)}
        style={{ width, height }}
        noScroll={isNoScroll}
      >
        {children}
      </Scrollbar>
    </ScrollbarContext.Provider>
  );
}

CustomScrollbar.defaultProps = {
  className: '',
};

CustomScrollbar.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default CustomScrollbar;
