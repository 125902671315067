import { useState } from 'react';
import { useDispatch } from 'react-redux';

import api from '../../../api';
import useForm from '../../../hooks/useForm';
import {transformUserForSignUp} from '../../../adapters/users';
import {preAuthorize, setLoginStorageData} from '../../../store/authorizationSlice';

import {
  ApiError,
  InputType,
  Mask,
  MaxInputLength,
} from '../../../constants';

const formInputs = {
  firstName: {
    value: '',
    error: '',
    isRequired: true,
    label: 'First Name',
    maxLength: MaxInputLength.NAME,
    type: InputType.NAME,
  },
  lastName: {
    value: '',
    error: '',
    isRequired: true,
    label: 'Last Name',
    maxLength: MaxInputLength.NAME,
    type: InputType.NAME,
  },
  organization: {
    value: null,
    error: '',
    isRequired: true,
    label: 'Funeral Home',
    type: InputType.TYPEAHEAD,
  },
  regNumber: {
    value: '',
    error: '',
    isRequired: true,
    label: 'Registration Number',
    maxLength: MaxInputLength.REG_NUBMER,
    type: InputType.TEXT,
  },
  email: {
    value: '',
    error: '',
    isRequired: true,
    label: 'E-mail',
    maxLength: MaxInputLength.EMAIL,
    type: InputType.EMAIL,
  },
  phone: {
    value: '',
    error: '',
    isRequired: true,
    label: 'Phone',
    mask: Mask.PHONE_MASK,
    type: InputType.PHONE,
  },
  password: {
    value: '',
    error: '',
    isRequired: true,
    label: 'Password',
    type: InputType.PASSWORD,
  },
  repeatedPassword: {
    value: '',
    error: '',
    isRequired: true,
    label: 'Confirm password',
    type: InputType.REPEATED_PASSWORD,
  },
};

const useSignUpForm = () => {
  const [isVerifyPhoneScreen, setIsVerifyPhoneScreen] = useState(false);
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [signUpError, setSignUpError] = useState('');
  const [alreadyExistError, setAlreadyExistError] = useState('');
  const dispatch = useDispatch();

  const toggleIsAgreementAccepted = () => {
    setIsAgreementAccepted((prevState) => !prevState);
  };

  const submitForm = (formData) => {
    setIsDataLoading(true);
    const data = transformUserForSignUp(formData, isAgreementAccepted);
    api.post('/users/sign_up', data)
      .then((response) => {
        dispatch(setLoginStorageData({
          user: response.data,
          token: response.headers.authorization,
        }));
        dispatch(preAuthorize());
        setAlreadyExistError('');
        setSignUpError('');
        setIsDataLoading(false);
        setIsVerifyPhoneScreen(true);
      })
      .catch((error) => {
        setIsDataLoading(false);
        if (error.response?.data?.status === ApiError.USER_DUPLICATE) {
          setSignUpError('');
          setAlreadyExistError(error.response.data.userMessage);
        } else {
          setAlreadyExistError('');
          setSignUpError(error.response?.data?.userMessage || '');
        }
      });
  };

  const {
    checkFieldError,
    formFields,
    handleChange,
    handleSelectChange,
    handleSubmit,
    isFormValid,
  } = useForm(formInputs, submitForm);

  return {
    checkFieldError,
    formFields,
    handleChange,
    handleSelectChange,
    handleSubmit,
    alreadyExistError,
    isDataLoading,
    isFormValid,
    isAgreementAccepted,
    isVerifyPhoneScreen,
    toggleIsAgreementAccepted,
    signUpError,
  };
};

export default useSignUpForm;
