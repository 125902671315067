import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Table from './components/table';
import TableSidebar from './components/table-sidebar';

import { AppRoute, AuthorizationStatus } from '../../constants';

import './arrangements-page.scss';

const ArrangementsPage = () => {
  const { authorizationStatus } = useSelector((state) => state.authorization);

  if (authorizationStatus === AuthorizationStatus.NO_AUTH) {
    return <Redirect to={AppRoute.ROOT} />;
  }

  return (
    <div className='arrangements-page'>
      <TableSidebar />
      <Table />
    </div>
  );
};

export default ArrangementsPage;
