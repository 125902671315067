export const transformUserForSignUp = (data, isTermsAccepted) => {
  const currentDate = new Date().toISOString();
  const formattedPhone = data.phone.replace('+1', '').replace(/[^0-9]+/g, '');

  return {
    lastname: data.lastName,
    firstname: data.firstName,
    phone: formattedPhone,
    email: data.email.toLowerCase(),
    role: 'funeral_director',
    password: data.password,
    organizations: [data.organization.id],
    registration_number: data.regNumber,
    terms_accepted: isTermsAccepted,
    created_at: currentDate,
    updated_at: currentDate,
    crematorium: window.CREMATORIUM_ID,
  };
};

export const transformUserForSignIn = (data) => ({
  email: data.userEmail.toLowerCase(),
  password: data.userPassword,
});
