import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import ArrangementSidebar from '../arrangement-sidebar';
import { Loader } from '../../../common-components';
import Pagination from '../pagination';
import Status from '../status';
import Search from '../search';

import { formatTimeMinutesToUSLocal, formatDateToUSLocal } from '../../../../utils';
import useArrangementsList from '../../hooks/useArrangementsList';
import { ArrangementReturnUrgencyValue, KeyCode } from '../../../../constants';

import './table.scss';

const Table = () => {
  const [selectedRequest, setSelectedRequest] = useState(null);

  const {
    arrangementsList,
    dataLoading,
    decrementPage,
    endListCount,
    incrementPage,
    lastRowRef,
    pageCount,
    setFirstPage,
    setLastPage,
    skipArrangementsCount,
    startListCount,
    totalArrangementsCount,
    updateArrangementsList,
  } = useArrangementsList();
  const {dataLoading: funeralHomesLoading} = useSelector((state) => state.funeralHomes);

  const handleRowButtonKeyDown = (evt, rowData) => {
    if (evt.keyCode === KeyCode.SPACE) {
      setSelectedRequest(rowData);
    }
  };

  return (
    <div className={classNames('table', (dataLoading || funeralHomesLoading) && 'table_loading')}>
      <div className='table__header'>
        <div className='table__row table__row_header'>
          <div className='table__cell'>Status</div>
          <div className='table__cell'>Date</div>
          <div className='table__cell'>Time</div>
          <div className='table__cell'>Decedent</div>
          <div className='table__cell'>Need by</div>
          <div className='table__cell'>Payment method</div>
          <div className='table__cell'>Payment status</div>
        </div>
      </div>
      <div className='table__body'>
        {arrangementsList.length ? arrangementsList.map((rowData, idx) => (
          <div
            className={classNames('table__row', rowData.status === 'Canceled' && 'table__row_canceled', rowData.urgency === ArrangementReturnUrgencyValue.RUSH && rowData.status !== 'Canceled' && 'table__row_warn')}
            key={rowData.id}
            onClick={() => setSelectedRequest(rowData)}
            onKeyDown={(evt) => handleRowButtonKeyDown(evt, rowData)}
            ref={idx === arrangementsList.length - 1 ? lastRowRef : null}
            role='button'
            tabIndex='0'
          >
            <div className='table__cell'>
              <Status
                className='table__cell-status'
                status={rowData.status}
              />
            </div>
            <div className='table__cell'>{formatDateToUSLocal(rowData.date)}</div>
            <div className='table__cell'>{formatTimeMinutesToUSLocal(rowData.time)}</div>
            <div className='table__cell'>{rowData.decedentName}</div>
            <div className='table__cell table__cell_return'>
              <span>{rowData.returnNeedBy || '-'}</span>
              {rowData.returnNeedBy && rowData.returnDate && <span className='table__cell-return-date'>{formatDateToUSLocal(rowData.returnDate)}</span>}
            </div>
            <div className='table__cell'>{rowData.paymentMethod || 'Paid later'}</div>
            <div className='table__cell'>
              <Status
                className='table__cell-status'
                status={rowData.paymentStatus}
              />
            </div>
          </div>
        )) : <div className='table__no-data'>No Data Found</div>}
        { (dataLoading || funeralHomesLoading) && <Loader className='loader_position_overlay table__loader'/> }
      </div>

      <div className='table__footer'>
        <Search className='table__footer-section table__footer-section_search' />
        <Pagination
          className='table__footer-section table__footer-section_pagination pagination'
          isFirstPage={pageCount === 1}
          isLastPage={arrangementsList.length + skipArrangementsCount >= totalArrangementsCount}
          onFirstPageButtonClick={setFirstPage}
          onLastPageButtonClick={setLastPage}
          onPreviousPageButtonClick={decrementPage}
          onNextPageButtonClick={incrementPage}
          startListCount={startListCount}
          endListCount={endListCount}
          totalArrangementsCount={totalArrangementsCount}
        />
      </div>
      {selectedRequest && (
        <ArrangementSidebar
          arrangementData={selectedRequest}
          onArrangementRequestClose={() => setSelectedRequest(null)}
          onArrangementCanceled={updateArrangementsList}
        />
      )}
    </div>
  );
};

export default Table;
