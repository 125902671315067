import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { loadArrangementsList } from '../../../store/arrangementsSlice';
import { SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT } from '../../../constants';
import useIntersect from '../../../hooks/useIntersect';
import useWindowSize from '../../../hooks/useWindowSize';

const LAPTOP_WIDTH = 1024;

const useArrangementsList = () => {
  const {
    arrangementsList,
    dataLoading,
    dateFilters,
    paymentStatusFilter,
    searchFilter,
    statusFilter,
    totalArrangementsCount,
  } = useSelector((state) => state.arrangements);
  const dispatch = useDispatch();
  const [isAllListLoaded, setIsAllListLoaded] = useState(false);
  const [limit, setLimit] = useState(SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT);
  const [pageCount, setPageCount] = useState(1);
  const skipArrangementsCount = (pageCount - 1) * SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT;
  const [startListCount, setStartListCount] = useState(arrangementsList.length ? skipArrangementsCount + 1 : 0);
  const [endListCount, setEndListCount] = useState(arrangementsList.length + skipArrangementsCount);
  const [lastRowRef, entry] = useIntersect(isAllListLoaded);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < LAPTOP_WIDTH;
  const totalPages = Math.ceil(totalArrangementsCount / SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT);

  useEffect(() => {
    setStartListCount(arrangementsList.length ? skipArrangementsCount + 1 : 0);
    setEndListCount(arrangementsList.length + skipArrangementsCount);
  }, [arrangementsList]);

  useEffect(() => {
    dispatch(loadArrangementsList(limit, skipArrangementsCount));
  }, [dateFilters, pageCount, searchFilter, limit, statusFilter, paymentStatusFilter]);

  useEffect(() => {
    if (entry.isIntersecting && !isAllListLoaded && isMobile) {
      setLimit((prevLimit) => prevLimit + SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT);
    } else if (!isMobile && limit > SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT) {
      setLimit(SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT);
    }

    if (isMobile && pageCount > 1) {
      setPageCount(1);
    }
  }, [isAllListLoaded, entry, isMobile, pageCount]);

  useEffect(() => {
    if (arrangementsList.length >= totalArrangementsCount && !isMobile) {
      setIsAllListLoaded(true);
    } else {
      setIsAllListLoaded(false);
    }
  }, [arrangementsList, isMobile]);

  useEffect(() => {
    setPageCount(1);
  }, [dateFilters, searchFilter, statusFilter, paymentStatusFilter]);

  const incrementPage = () => {
    setPageCount((prevCount) => prevCount + 1);
  };

  const decrementPage = () => {
    setPageCount((prevCount) => prevCount - 1);
  };

  const setFirstPage = () => {
    setPageCount(1);
  };

  const setLastPage = () => {
    setPageCount(totalPages);
  };

  const updateArrangementsList = () => {
    dispatch(loadArrangementsList(limit, skipArrangementsCount));
  };

  return {
    arrangementsList,
    dataLoading,
    decrementPage,
    endListCount,
    incrementPage,
    lastRowRef,
    pageCount,
    setFirstPage,
    setLastPage,
    skipArrangementsCount,
    startListCount,
    totalArrangementsCount,
    updateArrangementsList,
  };
};

export default useArrangementsList;
