import { createSlice } from '@reduxjs/toolkit';

import api from '../api';
import { logout } from './authorizationSlice';
import { ApiError } from '../constants';
import { formatDateToISO } from '../utils';
import transformArrangementsFromApi from '../adapters/arrangement';

const initialState = {
  arrangementsList: [],
  dataLoading: false,
  dateFilters: {from: '', to: ''},
  paymentStatusFilter: '',
  searchFilter: '',
  statusFilter: '',
  totalArrangementsCount: 0,
};

export const arrangementsSlice = createSlice({
  name: 'arrangements',
  initialState,
  reducers: {
    setArrangementsList: (state, action) => {
      state.arrangementsList = action.payload;
    },
    setDataLoadingInProcess: (state, action) => {
      state.dataLoading = action.payload;
    },
    setDateFilters: (state, action) => {
      state.dateFilters = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = action.payload;
    },
    setTotalArrangementsCount: (state, action) => {
      state.totalArrangementsCount = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
    setPaymentStatusFilter: (state, action) => {
      state.paymentStatusFilter = action.payload;
    },
  },
});

export const {
  setArrangementsList,
  setDataLoadingInProcess,
  setDateFilters,
  setSearchFilter,
  setTotalArrangementsCount,
  setSortBy,
  setStatusFilter,
  setPaymentStatusFilter,
} = arrangementsSlice.actions;

export default arrangementsSlice.reducer;

export const loadArrangementsList = (limit, skip) => async (dispatch, getState) => {
  const currentSearchValue = getState().arrangements.searchFilter;
  const currentFilterFromValue = getState().arrangements.dateFilters.from;
  const currentFilterToValue = getState().arrangements.dateFilters.to;
  const currentStatusFilterValue = getState().arrangements.statusFilter;
  const currentPaymentStatusFilterValue = getState().arrangements.paymentStatusFilter;

  const deliveryDatePeriod = {
    from: currentFilterFromValue ? formatDateToISO(currentFilterFromValue) : '',
    to: currentFilterToValue ? formatDateToISO(currentFilterToValue) : '',
  };

  const sortBy = {delivery_date: 'desc', delivery_time: 'desc'};

  const params = {limit, skip};

  if (deliveryDatePeriod.from) {
    params.deliveryDatePeriod = {
      from: deliveryDatePeriod.from,
    };
  }

  if (deliveryDatePeriod.to && params.deliveryDatePeriod) {
    params.deliveryDatePeriod.to = deliveryDatePeriod.to;
  } else if (deliveryDatePeriod.to) {
    params.deliveryDatePeriod = {
      to: deliveryDatePeriod.to,
    };
  }

  if (currentSearchValue) {
    params.searchString = currentSearchValue;
  }

  if (Object.keys(sortBy).length) {
    params.sort = sortBy;
  }

  if (currentStatusFilterValue) {
    params.statusesList = `["${currentStatusFilterValue}"]`;
  }

  if (currentPaymentStatusFilterValue) {
    params.paymentStatusesList = `["${currentPaymentStatusFilterValue}"]`;
  }

  try {
    dispatch(setDataLoadingInProcess(true));
    const response = await api.get('/arrangements', {
      params,
      headers: {
        Authorization: getState().authorization.token,
      },
    });

    if (response.data) {
      dispatch(setTotalArrangementsCount(Number(response.headers['total-count'])));
      const transformedData = transformArrangementsFromApi(response.data);
      dispatch(setArrangementsList(transformedData));
    }
    dispatch(setDataLoadingInProcess(false));
  } catch (error) {
    if (error.response?.data?.status === ApiError.UNAUTHORIZED) {
      dispatch(logout());
    } else {
      console.error(error);
      dispatch(setDataLoadingInProcess(false));
    }
  }
};
