import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppRoute, AuthorizationStatus, LogoLocation } from '../../constants';
import Login from './components/login';
import {CemeteryLogo} from '../common-components';

import './main-page.scss';

const MainPage = () => {
  const { authorizationStatus } = useSelector((state) => state.authorization);

  if (authorizationStatus === AuthorizationStatus.AUTH) {
    const {origin} = window.location;
    if (window.ARRANGEMENT_FORM_URL) {
      window.location.href = `${origin}${window.ARRANGEMENT_FORM_URL}`;
    } else {
      return <Redirect to={AppRoute.ARRANGEMENTS} />;
    }
  }

  return (
    <div className='main-page'>
      <picture>
        <source srcSet="/main-bg/bg-desktop.jpg" media="(min-width: 1280px)" />
        <source srcSet="/main-bg/bg-tablet.jpg" media="(min-width: 768px)" />
        <img className="main-page__main-bg" alt="Cemetery landscape" />
      </picture>
      <div className='main-page__content'>
        <CemeteryLogo location={LogoLocation.BACKGROUND} />
        <div className='main-page__user-module'>
          <Login
            handleForgotPasswordButtonClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
