import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './chip.scss';

const Chip = ({
  className,
  children,
  id,
  isActive,
  onClick,
  value,
}) => (
  <button
    className={classNames('chip', className, isActive && 'chip_active')}
    id={id}
    onClick={onClick}
    type='button'
  >
    <span className='chip__text'>{value}</span>
    {children}
  </button>
);

Chip.defaultProps = {
  className: '',
  id: '',
  isActive: false,
  onClick: () => {},
  value: '',
};

Chip.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

export default Chip;
