import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../../buttons';
import { Loader } from '../../../common-components';
import { Input } from '../../input';
import {ReactComponent as IconSearch} from '../../../../assets/icons/icon-search.svg';
import {ReactComponent as IconCancel} from '../../../../assets/icons/icon-cancel.svg';

const getFormattedTextValue = (text, searchStr) => {
  const boldTextIndex = text.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
    ? text.toLowerCase().indexOf(searchStr.toLowerCase())
    : 0;
  const boldTextEndIndex = text.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
    ? boldTextIndex + searchStr.length
    : 0;
  const beginText = text.slice(0, boldTextIndex);
  const boldText = text.slice(boldTextIndex, boldTextEndIndex);
  const endText = text.slice(boldTextEndIndex);

  return (
    <>
      {beginText ? <span className="select__item-label">{beginText}</span> : null}
      {boldText ? (
        <span className="select__item-label select__item-label_bold">{boldText}</span>
      ) : null}
      {endText ? <span className="select__item-label">{endText}</span> : null}
    </>
  );
};

const SelectList = ({
  clearSearchStr,
  handleItemClick,
  handleSearchStrChange,
  listItems,
  listLoading,
  searchStr,
  type,
}) => {
  useEffect(() => {
    if (searchStr.length > 0) {
      clearSearchStr();
    }
  }, []);

  return (
    <div className='select__dropdown'>
      {
        listLoading
          ? <Loader noText />
          : (
            <>
              {type === 'search' && (
                <Input
                  id={`selectSearch${listItems[0]?.id}`}
                  className={classNames('select__search-input', searchStr.length > 0 && 'select__search-input_filled')}
                  placeholder='Search'
                  onChange={handleSearchStrChange}
                  value={searchStr}
                >
                  {searchStr.length > 0 && (
                  <Button
                    classNameCustom='select__search-input-button'
                    icon={<IconCancel width={16} height={16}/>}
                    onClick={clearSearchStr}
                  />
                  )}
                  <IconSearch className='select__search-input-icon'/>
                </Input>
              )}
              <div className='select__list'>
                {listItems.map((item) => (
                  <Button
                    className='select__item'
                    id={`selectOption-${item.id}`}
                    disabled={item.disabled}
                    key={item.id}
                    onClick={() => handleItemClick(item)}
                  >
                    {searchStr ? getFormattedTextValue(item.value, searchStr) : item.value}
                  </Button>
                ))}
              </div>
            </>
          )
      }
    </div>
  );
};

SelectList.defaultProps = {
  listItems: [],
  type: '',
  searchStr: '',
};

SelectList.propTypes = {
  clearSearchStr: PropTypes.func.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  handleSearchStrChange: PropTypes.func.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  searchStr: PropTypes.string,
  type: PropTypes.string,
};

export default SelectList;
