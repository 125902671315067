export const DAY_IN_MS = 86400000;
export const MAX_SERVICE_COUNT = 10;
export const MIN_NAME_LENGTH = 2;
export const SHOW_ARRANGEMENTS_COUNT_PER_PAGE_LIMIT = 10;

export const IS_LOGO_TEXT_SEPARATED = window.APP_CEMETERY_LOGO_TITLE || window.APP_CEMETERY_LOGO_SUBTITLE;

export const LogoLocation = {
  BACKGROUND: 'background',
  LOGIN: 'login',
  SIDEBAR: 'sidebar',
};

export const ApiError = {
  SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  USER_DUPLICATE: 422,
};

export const AppInstallation = {
  ALBANY: 'albany',
  DEMO: 'demo',
  FOREST_HILL: 'forest-hill',
  WOODLAWN: 'woodlawn',
};

export const isAlbanyInstallation = window.CEMETERY_INSTALLATION === AppInstallation.ALBANY;
export const isDemoInstallation = window.CEMETERY_INSTALLATION === AppInstallation.DEMO;
export const isForestHillInstallation = window.CEMETERY_INSTALLATION === AppInstallation.FOREST_HILL;
export const isWoodlawnInstallation = window.CEMETERY_INSTALLATION === AppInstallation.WOODLAWN;

export const AppRoute = {
  ARRANGEMENTS: '/arrangements',
  POLICY: '/policy',
  ROOT: '/',
  TERMS: '/terms',
};

export const ArrangementTab = {
  SUMMARY: 'Summary',
  DOCUMENTS: 'Documents',
};

export const ArrangementReturnNeedByApiValue = {
  READY_BY: 'ready_by',
  DELIVERED_BY: 'delivered_by',
  MAILED_BY: 'mailed_by',
};

export const ArrangementReturnNeedByValue = {
  READY_BY: 'Ready by',
  DELIVERED_BY: 'Delivered by',
  MAILED_BY: 'Mailed by',
};

export const ARRANGEMENT_RETURN_NEED_BY_OPTIONS = [
  {id: 'arrangementReturnNeedByDefault', value: null, apiValue: null},
  {id: 'arrangementReturnNeedBy1', value: ArrangementReturnNeedByValue.READY_BY, apiValue: ArrangementReturnNeedByApiValue.READY_BY},
  {id: 'arrangementReturnNeedBy2', value: ArrangementReturnNeedByValue.DELIVERED_BY, apiValue: ArrangementReturnNeedByApiValue.DELIVERED_BY},
  {id: 'arrangementReturnNeedBy3', value: ArrangementReturnNeedByValue.MAILED_BY, apiValue: ArrangementReturnNeedByApiValue.MAILED_BY},
];

export const ArrangementReturnInApiValue = {
  METAL_CAN: 'metal_can',
  PAPER_BAG: 'paper_bag',
  PLASTIC_BOX: 'plastic_box',
  URN: 'urn',
};

export const ArrangementReturnInValue = {
  METAL_CAN: 'Metal Can',
  PAPER_BAG: 'Paper Bag',
  PLASTIC_BOX: 'Plastic Box',
  URN: 'Provided Urn',
};

export const ARRANGEMENT_RETURN_IN_OPTIONS = [
  {id: 'returnInOption1', value: ArrangementReturnInValue.PLASTIC_BOX, apiValue: ArrangementReturnInApiValue.PLASTIC_BOX},
  {id: 'returnInOption2', value: ArrangementReturnInValue.PAPER_BAG, apiValue: ArrangementReturnInApiValue.PAPER_BAG},
  {id: 'returnInOption3', value: ArrangementReturnInValue.URN, apiValue: ArrangementReturnInApiValue.URN},
  {id: 'returnInOption4', value: ArrangementReturnInValue.METAL_CAN, apiValue: ArrangementReturnInApiValue.METAL_CAN},
];

export const ArrangementReturnNotificationApiValue = {
  TEXT: 'text',
  PHONE_CALL: 'phone_call',
  EMAIL: 'email',
};

export const ArrangementReturnNotificationValue = {
  TEXT: 'Text',
  PHONE_CALL: 'Call',
  EMAIL: 'Email',
};

export const ARRANGEMENT_RETURN_NOTIFICATION_OPTIONS = [
  {id: 'returnNotificationOption1', value: ArrangementReturnNotificationValue.TEXT, apiValue: ArrangementReturnNotificationApiValue.TEXT},
  {id: 'returnNotificationOption2', value: ArrangementReturnNotificationValue.PHONE_CALL, apiValue: ArrangementReturnNotificationApiValue.PHONE_CALL},
  {id: 'returnNotificationOption3', value: ArrangementReturnNotificationValue.EMAIL, apiValue: ArrangementReturnNotificationApiValue.EMAIL},
];

export const ArrangementReturnUrgencyValue = {
  NO_RUSH: 'No Rush',
  RUSH: 'Rush',
};

export const ARRANGEMENT_RETURN_URGENCY_OPTIONS = [
  {id: 'returnUrgencyOption1', value: ArrangementReturnUrgencyValue.NO_RUSH, apiValue: false},
  {id: 'returnUrgencyOption2', value: ArrangementReturnUrgencyValue.RUSH, apiValue: true},
];

export const AuthorizationStatus = {
  AUTH: 'AUTH',
  PRE_AUTH: 'PRE_AUTH',
  NO_AUTH: 'NO_AUTH',
};

export const ContainerApiValue = {
  AIRTRAY_TO_CARDBOARD_BOX: 'airtray_to_cardboard_box',
  CARDBOARD_BOX: 'cardboard_box',
  CASKET: 'casket',
  CLOTH_CASKET: 'cloth_casket',
  CLOTH_OR_WOOD_CASKET: 'cloth_or_wood_casket',
  CONTAINER: 'container',
  ONE_TIME_USE_CONTAINER: 'one_time_use_container',
  OTHER: 'other',
  WOOD_CASKET: 'wood_casket',
};

export const ContainerValue = {
  AIRTRAY_TO_CARDBOARD_BOX: 'Airtray to Cardboard Box',
  CARDBOARD_BOX: 'Cardboard Box',
  CASKET: 'Casket',
  CLOTH_CASKET: 'Cloth Casket',
  CLOTH_OR_WOOD_CASKET: 'Cloth or Wood Casket',
  CONTAINER: 'Alternative Container',
  ONE_TIME_USE_CONTAINER: 'One-time use Container',
  OTHER: 'Other',
  WOOD_CASKET: 'Wood Casket',
};

export const CONTAINER_TYPES_ALL = [
  {id: 'containerType1', value: ContainerValue.AIRTRAY_TO_CARDBOARD_BOX, apiValue: ContainerApiValue.AIRTRAY_TO_CARDBOARD_BOX},
  {id: 'containerType2', value: ContainerValue.CARDBOARD_BOX, apiValue: ContainerApiValue.CARDBOARD_BOX},
  {id: 'containerType3', value: ContainerValue.CASKET, apiValue: ContainerApiValue.CASKET},
  {id: 'containerType4', value: ContainerValue.CLOTH_CASKET, apiValue: ContainerApiValue.CLOTH_CASKET},
  {id: 'containerType5', value: ContainerValue.CLOTH_OR_WOOD_CASKET, apiValue: ContainerApiValue.CLOTH_OR_WOOD_CASKET},
  {id: 'containerType6', value: ContainerValue.CONTAINER, apiValue: ContainerApiValue.CONTAINER},
  {id: 'containerType7', value: ContainerValue.ONE_TIME_USE_CONTAINER, apiValue: ContainerApiValue.ONE_TIME_USE_CONTAINER},
  {id: 'containerType8', value: ContainerValue.OTHER, apiValue: ContainerApiValue.OTHER},
  {id: 'containerType9', value: ContainerValue.WOOD_CASKET, apiValue: ContainerApiValue.WOOD_CASKET},
];

export const CremationStatus = {
  ARCHIVED: 'archived',
  CANCELED: 'canceled',
  CREMATED: 'cremated',
  IN_STORAGE: 'in_storage',
  NEW: 'new',
  RECEIVED: 'received',
  READY: 'ready',
};

export const CREMATION_STATUSES = [
  {id: 'cremationStatus1', value: 'Archived', apiValue: CremationStatus.ARCHIVED},
  {id: 'cremationStatus2', value: 'Canceled', apiValue: CremationStatus.CANCELED},
  {id: 'cremationStatus3', value: 'Cremated', apiValue: CremationStatus.CREMATED},
  {id: 'cremationStatus4', value: 'In Storage', apiValue: CremationStatus.IN_STORAGE},
  {id: 'cremationStatus5', value: 'New', apiValue: CremationStatus.NEW},
  {id: 'cremationStatus6', value: 'Ready', apiValue: CremationStatus.READY},
  {id: 'cremationStatus7', value: 'Received', apiValue: CremationStatus.RECEIVED},
];

export const DateFilter = {
  DEFAULT: 'default',
  TODAY: 'Today',
  THREE_DAYS: '3 days',
  WEEK: 'Week',
};

export const DATE_FILTERS = [DateFilter.TODAY, DateFilter.THREE_DAYS, DateFilter.WEEK];

export const DeathCauseValue = {
  ACCIDENT: 'Accident',
  FETAL: 'Fetal',
  HOMICIDE: 'Homicide',
  NATURAL_CAUSE: 'Natural Cause',
  NOT_GIVEN: 'Not Given',
  PENDING_INVESTIGATION: 'Pending Investigation',
  SUICIDE: 'Suicide',
  UNDETERMINED: 'Undetermined Circumstances',
};

export const DeathCauseApiValue = {
  ACCIDENT: 'accident',
  FETAL: 'fetal',
  HOMICIDE: 'homicide',
  NATURAL_CAUSE: 'natural_cause',
  NOT_GIVEN: 'not_given',
  PENDING_INVESTIGATION: 'pending_investigation',
  SUICIDE: 'suicide',
  UNDETERMINED: 'undetermined',
};

export const DEATH_CAUSE_TYPES = [
  {id: 'deathCauseType1', value: DeathCauseValue.NATURAL_CAUSE, apiValue: DeathCauseApiValue.NATURAL_CAUSE},
  {id: 'deathCauseType2', value: DeathCauseValue.ACCIDENT, apiValue: DeathCauseApiValue.ACCIDENT},
  {id: 'deathCauseType3', value: DeathCauseValue.HOMICIDE, apiValue: DeathCauseApiValue.HOMICIDE},
  {id: 'deathCauseType4', value: DeathCauseValue.SUICIDE, apiValue: DeathCauseApiValue.SUICIDE},
  {id: 'deathCauseType5', value: DeathCauseValue.UNDETERMINED, apiValue: DeathCauseApiValue.UNDETERMINED},
  {id: 'deathCauseType6', value: DeathCauseValue.PENDING_INVESTIGATION, apiValue: DeathCauseApiValue.PENDING_INVESTIGATION},
  {id: 'deathCauseType7', value: DeathCauseValue.NOT_GIVEN, apiValue: DeathCauseApiValue.NOT_GIVEN},
  {id: 'deathCauseType8', value: DeathCauseValue.FETAL, apiValue: DeathCauseApiValue.FETAL},
];

export const DecedentOtherRemarkApi = {
  JEWELRY: 'jewelry',
  IMPLANTS: 'implants',
  PACEMAKER: 'pacemaker',
  COVID: 'covid',
  CONTAGIOUS_DISEASE: 'contagious_disease',
};

export const DecedentOtherRemark = {
  [DecedentOtherRemarkApi.JEWELRY]: 'Jewelry',
  [DecedentOtherRemarkApi.IMPLANTS]: 'Implants',
  [DecedentOtherRemarkApi.PACEMAKER]: 'Pacemaker',
  [DecedentOtherRemarkApi.COVID]: 'COVID-19',
  [DecedentOtherRemarkApi.CONTAGIOUS_DISEASE]: 'Contagious Disease',
};

export const Document = {
  BODY_DELIVERY: 'bodyDeliveryReceipt',
  BURIAL: 'burialTransitPermit',
  CERTIFICATE: 'certificateCremation',
  CREMATION: 'authorizationCremationDisposition',
  DEED: 'deed',
  OTHER: 'other',
  PCP: 'pcp',
  REMAINS: 'remainsReceipt',
  SIGNED_BODY_DELIVERY_RECEIPT: 'signedBodyDeliveryReceipt',
  UIS: 'uis',
};

export const DocumentLabel = {
  [Document.BODY_DELIVERY]: 'Body Delivery Receipt',
  [Document.BURIAL]: 'Burial - Transit Permit',
  [Document.CERTIFICATE]: 'Certificate of Cremation',
  [Document.CREMATION]: 'Authorization for Cremation and Disposition',
  [Document.DEED]: 'Certificate of Ownership',
  [Document.OTHER]: 'Other',
  [Document.REMAINS]: 'Remains Receipt',
  [Document.PCP]: 'Permanent Placement for Cremated Remains',
  [Document.SIGNED_BODY_DELIVERY_RECEIPT]: 'Signed Body Delivery Receipt',
  [Document.UIS]: 'UIS',
};

export const ErrorText = {
  DATE_ERROR: 'This is not a valid date',
  DEFAULT: 'Something went wrong. Please try again later.',
  EMAIL_FORMAT_INVALID_ERROR: 'This is not a valid email address',
  FAILED_AUTH: 'Login or password is incorrect',
  FIELD_IS_REQUIRED: 'This field is required',
  NAME_FORMAT_INVALID_ERROR: 'This is not a valid name',
  NAME_MIN_LENGTH: 'Please enter 2 or more characters',
  PASSWORD_LENGTH: 'The password must be at least 6 characters',
  PASSWORDS_MATCH: 'Passwords do not match',
  ROLE_ERROR: 'You are not authorized to access this interface',
  PHONE_NUMBER_ERROR: 'This is not a valid phone number',
};

export const InputType = {
  AGE: 'age',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  EMAIL: 'email',
  NAME: 'name',
  NUMBER: 'number',
  PASSWORD: 'password',
  REPEATED_PASSWORD: 'repeatedPassword',
  PHONE: 'phone',
  SELECT: 'select',
  TEXT: 'text',
  TYPEAHEAD: 'typeahead',
};

export const KeyCode = {
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
};

export const Mask = {
  DATE_MASK: '99/99/9999',
  PHONE_MASK: '(999) 999-9999',
};

export const MaxInputLength = {
  EMAIL: '254',
  NAME: '30',
  REG_NUBMER: '15',
};

export const Month = {
  0: 'January',
  1: 'Febrary',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const PaymentMethod = {
  BANK_TRANSFER: 'Bank Transfer',
  BILL: 'Bill Me',
  CASH_CHECK: 'Cash/Check',
  CHECK: 'Check',
  DEBIT_CREDIT_CARD: 'Debit /Credit Card',
  PREPAID: 'Prepaid',
};

export const PaymentMethodApiValue = {
  BANK_TRANSFER: 'bank_transfer',
  BILL: 'bill_me',
  CASH_CHECK: 'cash',
  CHECK: 'check',
  DEBIT_CREDIT_CARD: 'credit_card',
  PREPAID: 'prepaid',
};

export const PAYMENT_METHOD_OPTIONS = [
  {id: 'paymentMethod1', value: PaymentMethod.DEBIT_CREDIT_CARD, apiValue: PaymentMethodApiValue.DEBIT_CREDIT_CARD},
  {id: 'paymentMethod2', value: PaymentMethod.BANK_TRANSFER, apiValue: PaymentMethodApiValue.BANK_TRANSFER},
  {id: 'paymentMethod3', value: PaymentMethod.CASH_CHECK, apiValue: PaymentMethodApiValue.CASH_CHECK},
  {id: 'paymentMethod4', value: PaymentMethod.CHECK, apiValue: PaymentMethodApiValue.CHECK},
  {id: 'paymentMethod5', value: PaymentMethod.PREPAID, apiValue: PaymentMethodApiValue.PREPAID},
  {id: 'paymentMethod6', value: PaymentMethod.BILL, apiValue: PaymentMethodApiValue.BILL},
];

export const PaymentStatus = {
  PAID: 'paid',
  PENDING_PAID: 'pending_payment',
  UNPAID: 'unpaid',
};

export const PAYMENT_STATUSES = [
  {id: 'paymentStatus1', value: 'Paid', apiValue: PaymentStatus.PAID},
  {id: 'paymentStatus2', value: 'Pending payment', apiValue: PaymentStatus.PENDING_PAID},
  {id: 'paymentStatus3', value: 'Unpaid', apiValue: PaymentStatus.UNPAID},
];

export const Regex = {
  DATE: /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/,
  DIGIT: /^[0-9]*$/,
  EMAIL: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
  FILE_MIME_DOCUMENT: /image\/(jpeg|png|jpg)|application\/pdf/,
  NAME: /(^[a-zA-Z.'"`’\s-]+$)/,
  PASSWORD: /^.{6,}$/,
};

export const Role = {
  FUNERAL_DIRECTOR: 'funeral_director',
};

export const SexValue = {
  FEMALE: 'Female',
  MALE: 'Male',
  NON_BINARY: 'Non-Binary',
};

export const SexApiValue = {
  FEMALE: 'female',
  MALE: 'male',
  NON_BINARY: 'non_binary',
};

export const SEX_TYPES = [
  {id: 'sexType1', value: SexValue.MALE, apiValue: SexApiValue.MALE},
  {id: 'sexType2', value: SexValue.FEMALE, apiValue: SexApiValue.FEMALE},
  {id: 'sexType3', value: SexValue.NON_BINARY, apiValue: SexApiValue.NON_BINARY},
];

export const StorageData = {
  USER: 'afs_sales_user',
  TOKEN: 'afs_sales_jwt',
};

export const SuffixValue = {
  ESQUIRE: 'Esq',
  FIFTH: 'V',
  FOURTH: 'IV',
  JUNIOR: 'Jr',
  SECOND: 'II',
  SENIOR: 'Sr',
  THIRD: 'III',
  USA_RET: 'Ret',
  US_ARMY: 'USA',
  US_AIR_FORCE: 'USAF',
  US_MARINE_CORPS: 'USMC',
  US_NAVY: 'USN',
};

export const SuffixApiValue = {
  ESQUIRE: 'esquire',
  FIFTH: 'fifth',
  FOURTH: 'fourth',
  JUNIOR: 'junior',
  SECOND: 'second',
  SENIOR: 'senior',
  THIRD: 'third',
  USA_RET: 'usa_ret',
  US_ARMY: 'us_army',
  US_AIR_FORCE: 'us_air_force',
  US_MARINE_CORPS: 'us_marine_corps',
  US_NAVY: 'us_navy',
};

export const SUFFIX_TYPES = [
  {id: 'suffixDefault', value: null, apiValue: null},
  {id: 'suffixType1', value: SuffixValue.SECOND, apiValue: SuffixApiValue.SECOND},
  {id: 'suffixType2', value: SuffixValue.THIRD, apiValue: SuffixApiValue.THIRD},
  {id: 'suffixType3', value: SuffixValue.FOURTH, apiValue: SuffixApiValue.FOURTH},
  {id: 'suffixType4', value: SuffixValue.FIFTH, apiValue: SuffixApiValue.FIFTH},
  {id: 'suffixType5', value: SuffixValue.ESQUIRE, apiValue: SuffixApiValue.ESQUIRE},
  {id: 'suffixType6', value: SuffixValue.JUNIOR, apiValue: SuffixApiValue.JUNIOR},
  {id: 'suffixType7', value: SuffixValue.SENIOR, apiValue: SuffixApiValue.SENIOR},
  {id: 'suffixType8', value: SuffixValue.USA_RET, apiValue: SuffixApiValue.USA_RET},
  {id: 'suffixType9', value: SuffixValue.US_ARMY, apiValue: SuffixApiValue.US_ARMY},
  {id: 'suffixType10', value: SuffixValue.US_AIR_FORCE, apiValue: SuffixApiValue.US_AIR_FORCE},
  {id: 'suffixType11', value: SuffixValue.US_MARINE_CORPS, apiValue: SuffixApiValue.US_MARINE_CORPS},
  {id: 'suffixType12', value: SuffixValue.US_NAVY, apiValue: SuffixApiValue.US_NAVY},
];
