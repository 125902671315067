import { useEffect } from 'react';

export default function useKeyDown(
  targetKey,
  handler,
  ref = null,
  isListenerStopped = false,
) {
  useEffect(() => {
    const innerRef = ref;

    const handleKeyDown = (evt) => {
      if (evt.keyCode === targetKey) {
        evt.target.blur();
        handler();
      }
    };

    if (!isListenerStopped && innerRef && innerRef.current) {
      innerRef.current.addEventListener('keydown', handleKeyDown);
    } else if (!isListenerStopped) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (innerRef && innerRef.current) {
        innerRef.current.removeEventListener('keydown', handleKeyDown);
      } else {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [targetKey, handler, isListenerStopped]);
}
