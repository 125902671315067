import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Input} from '../input';
import SelectList from './components/select-list';
import {ReactComponent as IconDropdown} from '../../../assets/icons/icon-dropdown.svg';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useKeyDown from '../../../hooks/useKeyDown';
import { KeyCode } from '../../../constants';

import './select.scss';
import { Button } from '../buttons';

const Select = ({
  className,
  defaultValue,
  disabled,
  handleValueSet,
  id,
  isFlattened,
  label,
  listItems,
  listLoading,
  type,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [filteredList, setFilteredList] = useState(listItems);
  const [showMenu, setShowMenu] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectedOption) {
      handleValueSet(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (selectedOption) {
      setSelectedOption(defaultValue);
    }
  }, [listItems]);

  useEffect(() => {
    const filterList = () => {
      const newList = [...listItems.filter((item) => item.value.toLowerCase().includes(searchStr.toLowerCase()))];
      setFilteredList(newList);
    };

    if (searchStr.length > 0) {
      filterList();
    } else {
      setFilteredList(listItems);
    }
  }, [searchStr, listItems]);

  const closeMenu = () => setShowMenu(false);
  const toggleMenu = () => setShowMenu((prevShowMenuStatus) => !prevShowMenuStatus);

  useOnClickOutside(selectRef, closeMenu);
  useKeyDown(KeyCode.ESCAPE, closeMenu, null, !showMenu);

  /**
   * Sets selected option to the state and triggers handleValueSet callback
   * @param  {object} item {selected option}
   */
  const setValue = useCallback((item) => {
    setSelectedOption(item);
    setShowMenu(false);
  }, [selectedOption, showMenu]);

  const handleSearchStrChange = useCallback(({ target: { value } }) => {
    setSearchStr(value);
  }, []);

  const clearSearchStr = useCallback(() => setSearchStr(''), []);

  return (
    <Input
      className={classNames('input_select input_with-icon select', disabled && 'select_disabled', className)}
      id={id}
      isFlattened={isFlattened}
      disabled={disabled}
      label={label}
      readOnly
      onClick={toggleMenu}
      ref={selectRef}
      value={selectedOption?.value || ''}
    >
      <Button
        ariaLabel='Toggle menu'
        classNameCustom={classNames('select__toggle', showMenu && 'select__toggle_reversed')}
        icon={<IconDropdown />}
        disabled={disabled}
        onClick={toggleMenu}
      />
      {
        showMenu && (
          <SelectList
            clearSearchStr={clearSearchStr}
            handleItemClick={setValue}
            handleSearchStrChange={handleSearchStrChange}
            listItems={filteredList}
            listLoading={listLoading}
            searchStr={searchStr}
            type={type}
          />
        )
      }
    </Input>
  );
};

Select.defaultProps = {
  className: '',
  defaultValue: null,
  handleValueSet: () => {},
  disabled: false,
  listItems: [],
  listLoading: false,
  type: '',
};

Select.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  handleValueSet: PropTypes.func,
  disabled: PropTypes.bool,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  listLoading: PropTypes.bool,
  type: PropTypes.string,
};

export default Select;
