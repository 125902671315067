import {
  ErrorText,
  LogoLocation,
  Mask,
  MIN_NAME_LENGTH,
  Month,
  Regex,
} from './constants';

export function getCemeteryLogoProps(location, isTextSeparated) {
  let smallLogoSrc;
  let fullLogoMobileSrc;
  let fullLogoDesktopSrc;
  let fullLogoWidth = 'auto';
  let separatedLogoWidth = 'auto';
  let separatedLogoHeight = 'auto';

  switch (location) {
    case LogoLocation.LOGIN:
      smallLogoSrc = '/logos/logo-small-colored.svg';
      fullLogoMobileSrc = '/logos/logo-header.svg';
      fullLogoDesktopSrc = '/logos/logo-header.svg';
      separatedLogoWidth = 40;
      separatedLogoHeight = 40;
      break;
    case LogoLocation.BACKGROUND:
      smallLogoSrc = '/logos/logo-small-white.svg';
      fullLogoMobileSrc = '/logos/logo-big.svg';
      fullLogoDesktopSrc = '/logos/logo-big.svg';
      fullLogoWidth = 260;
      separatedLogoWidth = 160;
      separatedLogoHeight = 152;
      break;
    case LogoLocation.SIDEBAR:
      smallLogoSrc = '/logos/logo-small-colored.svg';
      fullLogoMobileSrc = '/logos/logo-header.svg';
      fullLogoDesktopSrc = '/logos/logo-sidebar.svg';
      break;
    default:
      smallLogoSrc = '/logos/logo-small-colored.svg';
      fullLogoMobileSrc = '/logos/logo-header.svg';
      fullLogoDesktopSrc = '/logos/logo-header.svg';
  }

  const logoClasses = `cemetery-logo__img ${isTextSeparated ? 'cemetery-logo__img_with-text' : `cemetery-logo__img_${location}`}`;
  const logoMobileSrc = isTextSeparated ? smallLogoSrc : fullLogoMobileSrc;
  const logoDesktopSrc = isTextSeparated ? smallLogoSrc : fullLogoDesktopSrc;
  const logoWidth = isTextSeparated ? separatedLogoWidth : fullLogoWidth;
  const logoHeight = isTextSeparated ? separatedLogoHeight : 'auto';

  return {
    logoClasses,
    logoMobileSrc,
    logoDesktopSrc,
    logoWidth,
    logoHeight,
  };
}

export function checkRequiredField(value, isRequired = false) {
  if (isRequired && typeof value.trim === 'function' && !value.trim()) {
    return ErrorText.FIELD_IS_REQUIRED;
  }

  return '';
}

export function checkAgeError(age, isRequired = false) {
  if (Number(age) >= 150) {
    return ErrorText.AGE_EXCEEDS_ERROR;
  }

  return checkRequiredField(age, isRequired);
}

export function checkNameError(name, isRequired = false) {
  if (!Regex.NAME.test(name.trim())) {
    return ErrorText.NAME_FORMAT_INVALID_ERROR;
  }

  if (name.trim().length < MIN_NAME_LENGTH) {
    return ErrorText.NAME_MIN_LENGTH;
  }

  return checkRequiredField(name, isRequired);
}

export function checkEmailError(email, isRequired = false) {
  if (!Regex.EMAIL.test(email.trim())) {
    return ErrorText.EMAIL_FORMAT_INVALID_ERROR;
  }

  return checkRequiredField(email, isRequired);
}

export function checkPhoneError(phone, isRequired = false) {
  if (phone.length && phone.length !== Mask.PHONE_MASK.length) {
    return ErrorText.PHONE_NUMBER_ERROR;
  }

  return checkRequiredField(phone, isRequired);
}

export function checkDateError(date, isRequired = false) {
  if (date && (date.length !== Mask.DATE_MASK.length || !Regex.DATE.test(date))) {
    return ErrorText.DATE_ERROR;
  }

  return checkRequiredField(date, isRequired);
}

export function checkPasswordError(password, isRequired = false) {
  if (!Regex.PASSWORD.test(password.trim())) {
    return ErrorText.PASSWORD_LENGTH;
  }

  return checkRequiredField(password, isRequired);
}

export function checkConfirmedPasswordError(password, originalPassword, isRequired = false) {
  if (!Regex.PASSWORD.test(password.trim())) {
    return ErrorText.PASSWORD_LENGTH;
  }

  if (password !== originalPassword) {
    return ErrorText.PASSWORDS_MATCH;
  }

  return checkRequiredField(password, isRequired);
}

export function formatTimeToUSLocal(hours, minutes) {
  const formattedHour = hours < 10 ? `0${hours}` : hours;
  const formattedHourPM = hours - 12 < 10 ? `0${hours - 12}:${String(minutes).length < 2 ? `0${minutes}` : minutes} PM` : `${hours - 12}:${String(minutes).length < 2 ? `0${minutes}` : minutes} PM`;
  // eslint-disable-next-line
  return hours > 12 ? formattedHourPM : hours === 12 ? `12:${String(minutes).length < 2 ? `0${minutes}` : minutes} PM` : `${formattedHour}:${String(minutes).length < 2 ? `0${minutes}` : minutes} AM`;
}

export function formatTimeMinutesToUSLocal(timeInMinutes) {
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  return formatTimeToUSLocal(hours, minutes);
}

export function formatDateToUSLocal(value) {
  return new Date(value).toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'});
}

export function formatDateToISO(value) {
  return new Date(value).toISOString();
}

export function getDateYear(date) {
  return new Date(date).getFullYear();
}

export function getDateMonth(date) {
  return Month[new Date(date).getMonth()];
}

export function getDateNumber(date) {
  return new Date(date).getDate();
}

export function getDefaultTodayDate() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function getDigits(value) {
  return value.replace(/[^\d]/g, '');
}

/**
 * Calculates sum of services
 * @param  {array} services {array of object's keys: count, service(obj) }
 * @return {number} {total price amount of all list services}
 */
export function sumServicesAmount(services) {
  return services.reduce((sum, item) => sum + item.count * item.service.price, 0);
}

/**
 * Transforms cents amount in dollars US format
 * @param  {number} value {cents}
 * @return {string} {in dollars with $}
 */
export function formatAsUsdDollars(value) {
  return typeof value === 'number' ? (value / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '';
}
