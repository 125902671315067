import { useState, useEffect } from 'react';
import { InputType } from '../constants';
import {
  checkAgeError,
  checkConfirmedPasswordError,
  checkDateError,
  checkEmailError,
  checkNameError,
  checkPasswordError,
  checkPhoneError,
  checkRequiredField,
} from '../utils';

const useForm = (initialFields = {}, onSubmitCallback, onFieldSuccessInputCallback) => {
  const [formFields, setFormFields] = useState(initialFields);
  const [isFormValid, setIsFormValid] = useState(false);

  const getOriginalPasswordValue = () => Object.values(formFields).find((value) => value.type === InputType.PASSWORD).value;

  const handleChange = ({ target: { id, value } }, validatorBeforeChange) => {
    if (typeof validatorBeforeChange === 'function' && !validatorBeforeChange(value)) {
      return;
    }

    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [id]: {
        ...prevFormFields[id],
        error: '',
        value,
      },
    }));
  };

  const handleSelectChange = (id, option) => {
    if (typeof onFieldSuccessInputCallback === 'function') {
      onFieldSuccessInputCallback(id, option.apiValue);
    }

    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [id]: {
        ...prevFormFields[id],
        error: '',
        value: option,
      },
    }));
  };

  const checkFieldError = ({ target: { id, value } }) => {
    const field = formFields[id];
    const trimmedValue = value.trim();
    let error = '';

    switch (field.type) {
      case InputType.AGE:
        error = checkAgeError(trimmedValue, field.isRequired);
        break;
      case InputType.NAME:
        error = checkNameError(trimmedValue, field.isRequired);
        break;
      case InputType.EMAIL:
        error = checkEmailError(trimmedValue, field.isRequired);
        break;
      case InputType.PHONE:
        error = checkPhoneError(trimmedValue, field.isRequired);
        break;
      case InputType.DATE:
        error = checkDateError(trimmedValue, field.isRequired);
        break;
      case InputType.PASSWORD:
        error = checkPasswordError(trimmedValue, field.isRequired);
        break;
      case InputType.REPEATED_PASSWORD:
        error = checkConfirmedPasswordError(trimmedValue, getOriginalPasswordValue(), field.isRequired);
        break;
      default:
        error = checkRequiredField(trimmedValue, field.isRequired);
    }

    if (error) {
      setIsFormValid(false);
    } else if (typeof onFieldSuccessInputCallback === 'function') {
      onFieldSuccessInputCallback(id, trimmedValue);
    }

    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [id]: {
        ...prevFormFields[id],
        error,
        value: trimmedValue,
      },
    }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const values = Object.entries(formFields).reduce(((prev, [name, { value }]) => ({ ...prev, [name]: value })), {});
    if (typeof onSubmitCallback === 'function') {
      onSubmitCallback(values);
    }
  };

  const validateField = (field) => {
    switch (field.type) {
      case InputType.AGE:
        return !checkAgeError(field.value, field.isRequired);
      case InputType.NAME:
        return !checkNameError(field.value, field.isRequired);
      case InputType.EMAIL:
        return !checkEmailError(field.value, field.isRequired);
      case InputType.PHONE:
        return !checkPhoneError(field.value, field.isRequired);
      case InputType.DATE:
        return !checkDateError(field.value, field.isRequired);
      case InputType.PASSWORD:
        return !checkPasswordError(field.value, field.isRequired);
      case InputType.REPEATED_PASSWORD:
        return !checkConfirmedPasswordError(field.value, getOriginalPasswordValue(), field.isRequired);
      default:
        return true;
    }
  };

  const validateForm = () => {
    const isAnyError = Object.entries(formFields).some((pair) => {
      const field = pair[1];
      if (field.isRequired && !field.value) {
        return true;
      }

      if (!validateField(field)) {
        return true;
      }

      return false;
    });

    if (isAnyError && isFormValid) {
      setIsFormValid(false);
    } else if (!isAnyError && !isFormValid) {
      setIsFormValid(true);
    }
  };

  const resetFormField = (fieldId) => {
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [fieldId]: {
        ...initialFields[fieldId],
        value: '',
      },
    }));
  };

  useEffect(() => {
    validateForm();
  }, [formFields]);

  return {
    checkFieldError,
    formFields,
    handleChange,
    handleSelectChange,
    handleSubmit,
    isFormValid,
    resetFormField,
  };
};

export default useForm;
