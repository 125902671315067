import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Status from '../../arrangements-page/components/status';

import './data-list.scss';

const DataList = ({
  id,
  className,
  pairs,
}) => (
  <dl className={classNames('data-list', className)}>
    {pairs.map((pair, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={`datalistPair${id}${idx}`}>
        <dd className='data-list__key'>{pair.key}</dd>
        {
          pair.isStatus
            ? (
              <dt className={classNames('data-list__value')}>
                <Status
                  className='data-list__value-data'
                  status={pair.value}
                />
              </dt>
            )
            : <dt className={classNames('data-list__value', pair.isValueBold && 'data-list__value_bold')}>{pair?.value}</dt>
        }
      </React.Fragment>
    ))}
  </dl>
);

DataList.defaultProps = {
  className: '',
};

DataList.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
  pairs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isStatus: PropTypes.bool,
    isValueBold: PropTypes.bool,
  })).isRequired,
};

export default DataList;
