import React from 'react';
import PropTypes from 'prop-types';

import {
  ExternalLink,
} from '../../../../../common-components';
import {ReactComponent as IconFile} from '../../../../../../assets/icons/icon-file.svg';

import {
  DocumentLabel,
} from '../../../../../../constants';

const ArrangementSidebarDataDocuments = ({ documents }) => {
  const documentsLinks = documents.filter((doc) => DocumentLabel[doc.type]).map((doc) => ({
    url: doc.filename,
    label: doc.name || DocumentLabel[doc.type],
    type: doc.type,
  }));
  const isAnyDocumentAvailable = documentsLinks?.length > 0;

  return (
    <div className='arrangement-sidebar__data arrangement-sidebar__data_documents'>
      <div className="arrangement-sidebar__data-main">
        {isAnyDocumentAvailable ? (
          <div className='arrangement-sidebar__data-block'>
            <p className='arrangement-sidebar__data-header'>Documents</p>
            <div className='arrangement-sidebar__data-links'>
              {documentsLinks.map((doc, idx) => (
                <ExternalLink
                  className='arrangement-sidebar__data-link'
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${doc.type}-${idx}`}
                  to={doc.url}
                >
                  <IconFile />
                  <span className='arrangement-sidebar__data-link-text'>{doc.label}</span>
                </ExternalLink>
              ))}
            </div>
          </div>
        ) : <p className='arrangement-sidebar__data-empty'>No Documents</p>}
      </div>
    </div>
  );
};

ArrangementSidebarDataDocuments.defaultProps = {
  documents: [],
};

ArrangementSidebarDataDocuments.propTypes = {
  documents: PropTypes.array,
};

export default ArrangementSidebarDataDocuments;
