import React from 'react';

import '../../assets/styles/terms.scss';

const TermsPage = () => (
  <div className='terms'>
    <h1>All Funeral Services LLC</h1>
    <h2>Terms of Use</h2>
    <ol>
      <li>Terms of Use</li>
      <li>ACCEPTANCE AND REPRESENTATIONS</li>
      <li>Payment</li>
      <li>SALE PROCEEDS</li>
      <li>FEEs</li>
      <li>TAXES, FEES</li>
      <li>INDEMNIFICATION</li>
      <li>LIMITATIONS OF LIABILITY</li>
      <li>CUSTOMER INFORMATION, PRIVACY, CONFIDENTIALITY</li>
      <li>OUTSIDE CONTENT</li>
      <li>DISCLAIMER</li>
      <li>GOVERNING LAW, DISPUTES</li>
      <li>COPYRIGHTS; TRADEMARKS; PATENTS</li>
      <li>LICENSE; SOFTWARE</li>
      <li>IMPOSSIBILITY OF PERFORMANCE</li>
      <li>AFS SOFTWARE</li>
      <li>AMENDMENTS</li>
      <li>TERM</li>
      <li>MISCELLANEOUS</li>
    </ol>

    <p> AFS Terms and Conditions (the &ldquo;Agreement&rdquo;) are between All Funeral Services, LLC
      (&ldquo;AFS,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) and the person or entity registering for an Account
      (&ldquo;Customer&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo;).
    </p>

    <ol>
      <li>
        Terms of Use. This Agreement governs your access to, use of, and
        transactions made through AFS. Among other things, AFS facilitates and
        enables users to purchase products and services in connection with funerals
        (including funeral goods) and to access and use a set of features, including
        availability of burial plots at cemeteries. We may make available to you,
        certain services or products that are subject to additional terms and
        conditions. AFS is not a funeral home, funeral director, crematorium or
        cemetery (&ldquo;<span className="text-underline">Funeral Services Providers&rdquo;</span>), and by using our services and
        accepting the Terms of Use you acknowledge and agree that we are not a
        Funeral Services Provider, and you shall not hold us responsible for any
        federal, state or local laws (collectively, the &ldquo;<span className="text-underline">Laws</span>&rdquo;), including but not
        limited to, Laws related to provision of any funeral services, or warranty of
        funeral goods. We do not sell any goods or merchandise; we do not sell
        cemetery burial lots, but provide a platform to facilitate such transactions.
        <p>
          This Agreement incorporates by reference any terms and conditions for
          additional services that AFS may make available to you at some point.
        </p>
      </li>
      <li>
        ACCEPTANCE AND REPRESENTATIONS. By registering with AFS and by
        clicking &ldquo;YES, ACCEPT THE TERMS OF USE&rdquo; AND BY USING OUR WEBSITE
        AND SERVICES ON PROVIDED OR BEING LINKED BY OUR WEBSITE, YOU
        AGREE TO BEYOND BY THESE TERMS AND YOUR REPRESENT that (i) the
        execution, delivery and performance by the Customer of this Agreement,
        and any other document executed by the Customer in connection here with
        have been duly authorized and not and will not (a) conflict with or result in
        any breach or contravention of any of your contractual obligation or any
        order, injunction, writ or decree of any governmental or regulatory
        authority; (b) violate any law; (ii) no approval, consent, exemption,
        authorization, or other action by, or notice to, or filing with, any
        governmental authority is necessary or required in connection with the
        execution, delivery or performance of this Agreement; or (iii) all information
        provided or made available by you or on your behalf to AFS is accurate and
        complete and contains no material misstatement of facts or omits to state
        any material fact necessary to make the statements therein, in the light of
        the circumstances under which they were made misleading. Upon
        completion of your registration (completed by you or on your behalf by a
        Funeral Services Provider), an &ldquo;<span className="text-underline">Account</span>&rdquo; will be created for you. You are
        responsible for all activities that occur under your Account. To the extent
        you are purchasing a plot or other services for the burial of the deceased,
        you are legally allowed to do so. You are responsible for maintaining the
        security of the passwords associated with your Account; if you believe an
        unauthorized person has access to your password, Account you must
        contact us immediately.
        <b> YOU HEREBY CONFIRM YOUR AGREEMENT AND
          UNDERSTANDING THAT THE SALE IS NOT EFFECTIVE UNTIL YOU RECEIVE A
          CONFIRMATION OF SUCH SALE TO YOUR E-MAIL FROM AFS. AFS WILL
          ISSUE SUCH CONFIRMATION ONLY UPON A RECEIPT OF THE
          CONFIRMATION FROM THE CEMETERY OR THE OTHER FUNERAL SERVICES
          PROVIDER, AS APPLICABLE.
        </b>
      </li>
      <li>
        PAYMENT. You acknowledge and agree that AFS shall have no liability or
        obligation to you for pricing, billing, or collection disputes between yourself,
        the Funeral Services Provider or any other third party. You further
        acknowledge and agree that AFS does not control any Cemetery’s pricing,
        billing, or collection methods, and we make no representations or
        warranties related to those items.
      </li>
      <li>
        SALE PROCEEDS. All funds received from any transaction via AFS platform
        (directly, via credit card or via a payment processor retained by AFS), (&ldquo;
        <span className="text-underline">SaleProceeds</span>&rdquo;)
        will be remitted to the Cemetery (or its account with the agreed
        upon payment processor) or a relevant funeral goods/services
        seller/provider as applicable, at the conclusion of the transaction by the
        Customer.
      </li>
      <li>
        FEEs. AFS does not set or control fees and costs (other than a fee payable to
        AFS for the use of its platform by the Funeral Services Provider) and just
        merely lists such fees as provided to AFS by the applicable Funeral Services
        Provider.
      </li>
      <li>
        TAXES, FEES. The Customer will be responsible, as required under applicable
        law, for identifying and paying all taxes and other governmental fees and
        charges (and any penalties, interest, and other additions thereto) that are
        imposed on it or with respect to the transactions and payments under this
        Agreement.
      </li>
      <li>
        INDEMNIFICATION. You will defend, indemnify, and hold harmless AFS and
        its affiliates, and their respective directors, officers, employees,
        representatives, contractors, and agents, from and against any loss,
        damage, judgment, settlement, expense, interest, and any other liability
        (including reasonable attorneys&apos; fees and costs) related to or arising out of
        any third party allegation, claim, lawsuit, or proceeding (a &ldquo;<span className="text-underline">Claim</span>&rdquo;) to the
        extent such Claim is based on or related to: (a) any breach of the
        Agreement, (b) any unlawful, improper or negligent use by anyone of any
        product sold or service provided to you under the Agreement, (c) any
        dispute between you and your family members or the other Funeral
        Services Provider, (d) any interment services (including offer, sale,
        performance, and fulfillment), (e) cemetery materials, (f) claims brought by
        cemetery personnel or (h) your gross negligence or willful misconduct. You
        will use counsel reasonably satisfactory to us to defend each indemnified
        Claim. If at any time we reasonably determine that any indemnified Claim
        might adversely affect us, we may take control of the defense at our
        expense. You may not consent to the entry of any judgment or enter into
        any settlement of a Claim without our prior written consent, which may not
        be unreasonably withheld.
      </li>
      <li>
        LIMITATIONS OF LIABILITY. TO THE FULLEST EXTENT PERMITTED BY LAW,
        UNDER NO CIRCUMSTANCES WILL AFS BE LIABLE FOR ANY CONSEQUENTIAL,
        INCIDENTAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, INCLUDING BUT
        NOT LIMITED TO ANY LOST PROFITS, THAT RESULT FROM YOUR USE OF ANY
        SERVICES PROVIDED BY AFS, FROM SALE OR PURCHASE OF A PLOT OR YOUR
        OTHER SERVICES BY A CUSTOMER OR ANY OTHER FUNERAL SERVICES
        PROVIDER, EVEN IF AFS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. TO THE FULLEST EXTENT PERMITTED BY LAW, AFS&apos;S LIABILITY IN
        ALL EVENTS IS LIMITED TO THE PURCHASE PRICE PAID FOR THE PRODUCT
        OR SERVICE THAT GIVES RISE TO THE LIABILITY.
      </li>
      <li>
        CUSTOMER INFORMATION, PRIVACY, CONFIDENTIALITY.
        <ol >
          <li>
            <span className="text-underline">Customer Information.</span> We require you to provide information about
            you and the deceased to access and use the Account, and you may
            need to provide supplemental information to use or purchase some
            products or services. You will ensure that: (i) all information that you
            provide to us is at all times accurate and complete; (ii) you allow and
            approve sharing such information in the Account with us; and (iii)
            that such sharing of information is not in violation of any applicable
            law (including but not limited to HIPPA Regulations, HIPAA Privacy
            rules etc.). We may share information you provide with third parties
            for example we will share the names and other pertinent identifying
            information about the deceased with the crematorium or a
            cemetery.
          </li>
          <li>
            Privacy. Except as disclosed in this Agreement, our use of your
            information is governed by the terms of the All Funeral Services
            Privacy Policy (&ldquo;Privacy Policy&rdquo;) available on our website
            (<a href="https://www.allfuneral.com/" target="_blank" rel="noreferrer">www.allfuneral.com</a>). Please review to understand our practices
            with respect to your information. Please note that all information
            about users and customers associated with an Account may be
            shared with your organization.
          </li>
          <li>
            Confidentiality. In connection with your use of our services, we may
            share with you information which is identified as confidential or that
            should reasonably be considered confidential (&ldquo;Confidential
            Information&rdquo;). You will take all reasonable measures to avoid
            disclosure, dissemination or unauthorized use of Confidential
            Information, including, at a minimum, those measures you take to
            protect your own confidential information of a similar nature. You
            may use Confidential Information only in connection with your use
            of the Account. You will not disclose Confidential Information during
            the term of this Agreement or at any time during the 3-year period
            following the termination of the Agreement, unless required by law.
            This applies to all Confidential Information in your possession,
            regardless of when or where you receive it.
          </li>
        </ol>
      </li>
      <li>
        OUTSIDE CONTENT. Our website or AFS Software may direct, redirect or link
        to third party websites which are not hosted or administered by AFS, and
        which may provide information about products and services which are
        wholly provided by third parties which have no connection to AFS. You
        acknowledge and agree that: (i) AFS does not endorse any content, product,
        or service on third party websites; and (ii) we shall have no liability for third
        party websites or services.
      </li>
      <li>
        DISCLAIMER. UNLESS OTHERWISE SPECIFIED IN WRITING, ANY
        INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE)
        OR SERVICES MADE AVAILABLE TO YOU BY AFS ARE ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
        AVAILABLE&rdquo; BASIS, AND AFS MAKES NO REPRESENTATIONS OR WARRANTIES
        OF ANY KIND, EXPRESS OR IMPLIED, AS TO SUCH INFORMATION, CONTENT,
        MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES. TO THE
        FULLEST EXTENT PERMITTED BY LAW, AND UNLESS OTHERWISE SPECIFIED
        IN WRITING, AFS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, AS TO
        THE PRODUCTS IT SELLS AND THE SERVICES AND INFORMATION IT
        PROVIDES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY
        AND FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT OF
        INTELLECTUAL PROPERTY RIGHTS.
      </li>
      <li>
        GOVERNING LAW, DISPUTES.
        <ol>
          <li>
            Governing Law. The laws of the State of New York, without
            reference to conflict of law rules, as well as the Federal Arbitration
            Act and federal arbitration law, govern the Agreement and any
            dispute that might arise between you and us that pertains to this
            Agreement.
          </li>
          <li>
            Disputes. Any dispute or claim relating in any way to our
            services or this Agreement will be resolved by binding arbitration. To
            begin an arbitration proceeding, you must send a letter requesting
            arbitration and describing your claim to our address at [All Funeral
            Services, LLC 750 Lexington Avenue, 12th Floor, New York, NY
            10022. The arbitration will be conducted by the American Arbitration
            Association (AAA) under its rules. An arbitrator can award on an
            individual basis the same damages and relief as a court (including
            injunctive and declaratory relief or statutory damages). You may
            choose to have the arbitration conducted by telephone, based on
            written submissions, or at a mutually agreed location. We and you
            agree that any dispute resolution proceedings will be conducted only
            on an individual basis and not in a class, consolidated or
            representative action. If, for any reason, a claim proceeds in court
            rather than in arbitration we each waive any right to a jury trial. We
            and you both agree that you or we may bring suit in court to enjoin
            infringement or other misuse of intellectual property rights.
          </li>
        </ol>
      </li>
      <li>
        COPYRIGHTS; TRADEMARKS; PATENTS. Use of the Account and our services
        hereunder does not give you the right to frame, use or reproduce any of our
        copyrighted material, trademarks, service marks, or other proprietary rights
        or material, or to use any of our patents, except as expressly permitted in
        writing by AFS
      </li>
      <li>
        LICENSE; SOFTWARE. The following AFS Software Terms and License apply
        to your access and use of our software and services thereunder and all
        features and software (including any updates or upgrades to the software)
        and any related documentation we make available to you in connection
        therewith (the &ldquo;AFS Software&rdquo;). Subject to your compliance with the
        Agreement and your payment of any applicable fees, AFS or its content
        providers grant you a limited, non-exclusive, non-transferable, nonsublicensable license to access and use AFS Software. You may not use data
        mining, robots, or similar data gathering and extraction tools in connection
        with AFS services or AFS Software, nor may you download or copy AFS
        information, including any collection of product listings, descriptions,
        reviews, or prices without our consent or for the benefit of another service
        provider. The following terms apply to your use of AFS Software.
      </li>
      <li>
        IMPOSSIBILITY OF PERFORMANCE. AFS SHALL HAVE NO LIABILITY FOR NONDELIVERY OR DELAY IN DELIVERY OF ANY SERVICES PROCURED THROUGH
        OUR WEBSITE BY OTHER FUNERAL SERVICES PROVIDERS OR CUSTOMERS.
        WE SHALL HAVE NO LIABILITY ARISING FROM OR RELATED TO ANY EVENT
        BEYOND OUR REASONABLE CONTROL, WHETHER FORSEABLE BY US, YOU,
        OR ANOTHER FUNERAL PROVIDER.
      </li>
      <li>
        AFS SOFTWARE
        <ol>
          <li>
            You may use AFS Software solely for purposes of enabling you
            to use our services, as permitted by the Agreement. You may not
            incorporate any portion of the AFS Software into other programs or
            compile any portion of it in combination with other programs, or
            otherwise reproduce, duplicate, copy (except to exercise rights
            granted in this section), modify, exploit, create derivative works of,
            distribute, sell, resell, assign any rights to, or license the AFS Software
            in whole or in part. All software used in our website/software is the
            property of AFS or its software suppliers and is protected by U.S. and
            international copyright Laws. You may not make any derivative use of
            our services without the express written consent of AFS.
          </li>
          <li>
            When you use the AFS Software, you may also be using the
            services of one or more third parties, such as a wireless carrier or a
            mobile software provider. Your use of these third party services may
            be subject to the separate policies, terms of use, and fees of these
            third parties.
          </li>
          <li>
            You may not reverse engineer, decompile or disassemble,
            tamper with, or bypass any security associated with the AFS
            Software, whether in whole or in part.
          </li>
          <li>
            We may offer automatic or manual updates to the AFS
            Software at any time and without notice to you.
          </li>
          <li>
            All rights not expressly granted to you in the Agreement are
            reserved and retained by AFS or its licensors, suppliers, publishers,
            rights holders, or other content providers. The licenses granted by
            AFS terminate if you do not comply with the Agreement.
          </li>
          <li>
            In the event of any conflict between the Agreement and any
            other AFS or third-party terms applicable to any portion of AFS
            Software, such as open-source license terms or specific product
            feature restrictions that are conveyed to you in the feature
            experience, such other terms will control as to that portion of the AFS
            Software and to the extent of the conflict.
          </li>
        </ol>
      </li>
      <li>
        AMENDMENTS. We may, at any time in our discretion, change the
        Agreement by posting such a change on our website or by notifying your
        account administrators. YOUR CONTINUED USE OF OUR SERVICES AFTER WE
        CHANGE THE AGREEMENT CONSTITUTES YOUR ACCEPTANCE OF THE
        CHANGES. IF YOU DO NOT AGREE TO ANY CHANGES, YOU MAY CANCEL
        YOUR ACCOUNT OR REFRAIN FROM USING OUR SERVICES IMPLICATED BY
        SUCH CHANGES.
      </li>
      <li>
        TERM. This Agreement takes effect when you create an Account and
        continues in effect until you or we terminate it. We may terminate the
        Agreement by providing notice to your administrators. We reserve the right,
        in our sole discretion, and with or without notice unless required by
        applicable law, to refuse service; remove or edit content; modify, suspend
        or discontinue the availability of any features, products, or services;
        terminate your right to use some or all of our services; and/or cancel orders.
        You may terminate the Agreement by giving us notice and closing your
        Account. Upon termination, all rights and obligations under the Agreement
        automatically terminate except for rights of action occurring prior to
        termination, payment obligations, including your obligations under
        Indemnification, Limitation of Liability, Customer Information, Privacy &amp;
        Confidentiality Sections, Copyrights, Trademarks &amp; Patents and
        Miscellaneous Sections.
      </li>
      <li>
        MISCELLANEOUS.
        <ol>
          <li>
            Except in connection with a reorganization, merger, sale, or transfer of
            substantially all of your assets (in which cases you must promptly provide
            written notice following assignment), you may not assign the Agreement
            without our prior written consent. Subject to that restriction, the
            Agreement will be binding on, inure to, and be enforceable against the
            parties and their respective successors and permitted assigns.
          </li>
          <li>
            If any part of this Agreement is deemed unlawful, void, or
            unenforceable, then that part will be deemed severable from this
            Agreement and will not affect the validity and enforceability of any
            remaining provisions.
          </li>
          <li>
            Our failure to enforce your strict performance of any provisions of this
            Agreement will not constitute a waiver of our right to enforce such
            provisions or any other provision of this Agreement subsequently.
          </li>
          <li>
            In the event of any conflict between the Agreement and the Policies,
            the Agreement will prevail. In the event of any conflict between the
            Agreement and our future terms referred in last sentence of Section 1, such
            future terms will control.
          </li>
          <li>
            You consent to receive communications from us electronically,
            including through e-mails, text messages, and other electronic notices.
          </li>
          <li>
            All notices to us must be sent to the attention of our customer service
            at [All Funeral Services, LLC 750 Lexington Avenue, 12th Floor, New York,
            NY 10022.
          </li>
          <li>
            The Agreement constitutes the exclusive and complete agreement
            between us and you. We will not be bound by any term, condition,
            obligation, or other provision which is different from or in addition to the
            provisions of the Agreement or which may be in any order, receipt,
            acceptance, confirmation, correspondence or other document, including
            informational forms we complete, sign or otherwise provide to you for your
            convenience.
          </li>
        </ol>
      </li>
    </ol>

  </div>
);

export default TermsPage;
