import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../checkbox/checkbox';

const CheckboxList = ({
  className,
  onChange,
  listItems,
  selectedItems,
}) => (
  <div className={className}>
    {
      listItems.map((item) => (
        <Checkbox
          checked={selectedItems && selectedItems.includes(item)}
          id={`multiselectOption-${item.id}`}
          key={item.value}
          label={item.value}
          onChange={() => onChange(item)}
        />
      ))
    }
  </div>
);

CheckboxList.defaultProps = {
  className: '',
  onChange: () => {},
  listItems: [],
  selectedItems: [],
};

CheckboxList.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
  })),
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
  })),
};

export default CheckboxList;
