import React from 'react';
import PropTypes from 'prop-types';

import { LogoLocation } from '../../../constants';

import './logo-text.scss';

const LogoText = ({ location, title, subtitle }) => (
  <p className={`logo-text logo-text_${location}`}>
    {title && <span className='logo-text__title'>{title}</span>}
    {subtitle && <span className='logo-text__subtitle'>{subtitle}</span>}
  </p>
);

LogoText.defaultProps = {
  title: '',
  subtitle: '',
};

LogoText.propTypes = {
  location: PropTypes.oneOf(Object.values(LogoLocation)).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default LogoText;
