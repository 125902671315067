import { createSlice } from '@reduxjs/toolkit';

import api from '../api';
import {
  AuthorizationStatus,
  ErrorText,
  Role,
  StorageData,
} from '../constants';

const checkUserRole = (user) => {
  const parsedUser = JSON.parse(user);

  if (parsedUser.role !== Role.FUNERAL_DIRECTOR) {
    return null;
  }
  return parsedUser;
};

const user = localStorage.getItem(StorageData.USER)
  ? checkUserRole(localStorage.getItem(StorageData.USER))
  : null;
const token = localStorage.getItem(StorageData.TOKEN) ?? '';

const initialState = {
  authorizationStatus: user !== null && token.length > 0 ? AuthorizationStatus.AUTH : AuthorizationStatus.NO_AUTH,
  authorizationInProcess: false,
  authorizationError: '',
  user,
  token,
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    authorize: (state) => {
      state.authorizationStatus = AuthorizationStatus.AUTH;
    },
    changeAuthotizationInProcess: (state, action) => {
      state.authorizationInProcess = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem(StorageData.USER);
      localStorage.removeItem(StorageData.TOKEN);
      return {
        ...state,
        authorizationStatus: AuthorizationStatus.NO_AUTH,
        user: null,
        token: '',
      };
    },
    preAuthorize: (state) => {
      state.authorizationStatus = AuthorizationStatus.PRE_AUTH;
    },
    setAuthorizationError: (state, action) => {
      state.authorizationError = action.payload;
    },
    setLoginStorageData: (state, action) => {
      localStorage.setItem(StorageData.USER, JSON.stringify(action.payload.user));
      localStorage.setItem(StorageData.TOKEN, action.payload.token);

      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    },
  },
});

export const {
  authorize,
  changeAuthotizationInProcess,
  logout,
  preAuthorize,
  setAuthorizationError,
  setLoginStorageData,
} = authorizationSlice.actions;

export default authorizationSlice.reducer;

export const requireAuthorization = (data) => async (dispatch) => {
  try {
    dispatch(changeAuthotizationInProcess(true));
    const response = await api.post('/users/login', data);

    if (response.data) {
      const {role} = response.data;
      if (role !== Role.FUNERAL_DIRECTOR) {
        dispatch(setAuthorizationError(ErrorText.ROLE_ERROR));
      } else {
        dispatch(setLoginStorageData({
          user: response.data,
          token: response.headers.authorization,
        }));
        dispatch(authorize());
      }
    } else {
      dispatch(setAuthorizationError(ErrorText.DEFAULT));
    }
    dispatch(changeAuthotizationInProcess(false));
  } catch (error) {
    dispatch(setAuthorizationError(error.response?.data?.userMessage || ''));
    dispatch(changeAuthotizationInProcess(false));
  }
};
