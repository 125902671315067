import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SignIn from '../sign-in';
import SignUpForm from '../sign-up';

import './login.scss';

const Stage = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
};

const Login = ({ handleForgotPasswordButtonClick }) => {
  const [stage, setStage] = useState(Stage.SIGN_IN);

  const renderInnerComponent = (stageComponent) => {
    switch (stageComponent) {
      case Stage.SIGN_UP:
        return (
          <SignUpForm handleSignInButtonClick={() => setStage(Stage.SIGN_IN)}/>
        );
      default:
        return (
          <SignIn
            handleForgotPasswordButtonClick={handleForgotPasswordButtonClick}
            handleSignUpButtonClick={() => setStage(Stage.SIGN_UP)}
          />
        );
    }
  };

  return (
    <div className={classNames('login', stage === Stage.SIGN_UP && 'login_sign-up')}>
      {renderInnerComponent(stage)}
    </div>
  );
};

Login.propTypes = {
  handleForgotPasswordButtonClick: PropTypes.func.isRequired,
};

export default Login;
