import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonRouterLink = (
  {
    ariaLabel,
    children,
    className,
    classNameCustom,
    onClick,
    target,
    text,
    textClassName,
    to,
  },
) => (
  <Link
    aria-label={ariaLabel}
    className={classNames(className, classNameCustom && classNameCustom)}
    onClick={onClick}
    target={target}
    to={to}
  >
    {children}
    {text && <span className={textClassName || 'button__text'}>{text}</span>}
  </Link>
);

ButtonRouterLink.defaultProps = {
  ariaLabel: '',
  className: 'button',
  classNameCustom: '',
  onClick: () => {},
  target: '_self',
  text: null,
  textClassName: '',
};

ButtonRouterLink.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  classNameCustom: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  to: PropTypes.any.isRequired,
};

export default ButtonRouterLink;
