import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {ReactComponent as IconSignout} from '../../../../assets/icons/icon-signout.svg';
import {ReactComponent as IconUser} from '../../../../assets/icons/icon-user.svg';
import { Button } from '../../../ui-components';

import { logout } from '../../../../store/authorizationSlice';

import './signout.scss';

const Signout = ({
  className,
}) => {
  const {user} = useSelector((state) => state.authorization);
  const dispatch = useDispatch();
  const onSignOutButtonClick = () => dispatch(logout());
  const isShrink = user?.firstname.length > 15 || user?.lastname.length > 15;
  const isLongName = user?.firstname.length > 18 || user?.lastname.length > 18;

  return (
    <div className={classNames('signout', isLongName && 'signout_long-name', isShrink && 'signout_shrink', className)}>
      {isLongName || <IconUser className='signout__user-icon'/>}
      <p className='signout__user-name'>
        <span className='signout__user-name-part'>{user?.firstname}&nbsp;</span>
        <span className='signout__user-name-part'>{user?.lastname}</span>
      </p>
      <Button
        classNameCustom='signout__button'
        ariaLabel='Sign out'
        icon={<IconSignout />}
        onClick={onSignOutButtonClick}
      />
    </div>
  );
};

Signout.defaultProps = {
  className: '',
};

Signout.propTypes = {
  className: PropTypes.string,
};

export default Signout;
