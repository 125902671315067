import {
  CONTAINER_TYPES_ALL,
  CREMATION_STATUSES,
  PAYMENT_STATUSES,
  PAYMENT_METHOD_OPTIONS,
  PaymentStatus,
  ARRANGEMENT_RETURN_IN_OPTIONS,
  SUFFIX_TYPES,
  DEATH_CAUSE_TYPES,
  SEX_TYPES,
  DecedentOtherRemark,
  DecedentOtherRemarkApi,
  ARRANGEMENT_RETURN_NOTIFICATION_OPTIONS,
  ARRANGEMENT_RETURN_URGENCY_OPTIONS,
  ARRANGEMENT_RETURN_NEED_BY_OPTIONS,
} from '../constants';

const getStringOption = (apiType, options) => options.find((item) => item.apiValue === apiType)?.value || '';

const getDecedentFullName = (decedent) => {
  let name = decedent?.middlename
    ? `${decedent?.firstname} ${decedent?.middlename} ${decedent?.lastname}`
    : `${decedent?.firstname} ${decedent?.lastname}`;

  if (decedent?.suffix) {
    const suffix = SUFFIX_TYPES.find((item) => item.apiValue === decedent?.suffix)?.value || '';
    name = name.concat(` ${suffix}`);
  }

  return name;
};

const getOtherRemarks = (decedent) => Object.values(DecedentOtherRemarkApi)
  .filter((val) => decedent[val])
  .map((val) => DecedentOtherRemark[val])
  .join(', ');

const getPaymentStatus = (apiType) => {
  const zeroValue = PAYMENT_STATUSES.find((item) => item.apiValue === PaymentStatus.UNPAID)?.value;
  return apiType ? PAYMENT_STATUSES.find((item) => item.apiValue === apiType)?.value || '' : zeroValue;
};
// eslint-disable-next-line no-confusing-arrow
const getPaymentMethod = (record) => record ? PAYMENT_METHOD_OPTIONS.find((item) => item.apiValue === record.payment_method)?.value || '' : '';

const transformArrangementShapeFromApi = (apiArrangement) => ({
  id: apiArrangement.id,
  date: apiArrangement.delivery_date,
  time: apiArrangement.delivery_time,
  decedentName: `${apiArrangement.decedent?.firstname} ${apiArrangement.decedent?.lastname}`,
  decedentFullName: getDecedentFullName(apiArrangement.decedent),
  decedentFirstName: apiArrangement.decedent?.firstname || '',
  decedentLastName: apiArrangement.decedent?.lastname || '',
  decedentMiddleName: apiArrangement.decedent?.middlename || '',
  decedentWeight: apiArrangement.decedent?.estimated_weight,
  decedentAge: apiArrangement.decedent?.age,
  decedentSex: getStringOption(apiArrangement.decedent?.sex, SEX_TYPES),
  decedentDeathDate: apiArrangement.decedent?.date_of_death || null,
  decedentDeathPlace: apiArrangement.decedent?.place_of_death || '',
  decedentDeathCause: getStringOption(apiArrangement.decedent?.manner_of_death, DEATH_CAUSE_TYPES),
  decedentUIS: apiArrangement.decedent?.uis_bracelet_no,
  documents: apiArrangement.documents || [],
  containerType: getStringOption(apiArrangement?.decedent?.container, CONTAINER_TYPES_ALL),
  funeralHomeId: apiArrangement?.from_funeral_home?.id || '',
  funeralHomeName: apiArrangement?.from_funeral_home?.name || '',
  otherRemarks: getOtherRemarks(apiArrangement.decedent),
  notification: getStringOption(apiArrangement.notification, ARRANGEMENT_RETURN_NOTIFICATION_OPTIONS),
  returnDate: apiArrangement.return_date || null,
  returnNeedBy: getStringOption(apiArrangement.need_by, ARRANGEMENT_RETURN_NEED_BY_OPTIONS),
  returnIn: getStringOption(apiArrangement?.return_in, ARRANGEMENT_RETURN_IN_OPTIONS),
  paymentMethod: getPaymentMethod(apiArrangement.record_payment),
  paymentStatus: getPaymentStatus(apiArrangement.payment_status),
  services: apiArrangement.services || [],
  status: getStringOption(apiArrangement.status, CREMATION_STATUSES),
  specialInstruction: apiArrangement.special_instruction || '',
  urgency: getStringOption(apiArrangement.rush, ARRANGEMENT_RETURN_URGENCY_OPTIONS),
});

const transformArrangementsFromApi = (apiArrangements) => ([...apiArrangements.map((item) => transformArrangementShapeFromApi(item))]);

export default transformArrangementsFromApi;
