import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../../../ui-components';
import { CustomScrollbar, PopupCancelArrangement } from '../../../common-components';
import Status from '../status';
import Tabs from '../tabs';
import {ReactComponent as IconCancel} from '../../../../assets/icons/icon-cancel.svg';
import ArrangementSidebarDataDocuments from './components/arrangement-sidebar-data-documents';
import ArrangementSidebarDataSummary from './components/arrangement-sidebar-data-summary';

import api from '../../../../api';
import { ApiError, ArrangementTab } from '../../../../constants';
import { logout } from '../../../../store/authorizationSlice';
import usePopup from '../../../../hooks/usePopup';
import useTabs from '../../../../hooks/useTabs';
import useUnmountAnimation from '../../../../hooks/useUnmountAnimation';

import './arrangement-sidebar.scss';

const ArrangementSidebar = ({ arrangementData, onArrangementRequestClose, onArrangementCanceled }) => {
  const {token} = useSelector((state) => state.authorization);
  const dispatch = useDispatch();

  const {
    isPopupShown,
    showPopup,
    hidePopup,
  } = usePopup();

  const {
    activeTab,
    handleDocumentsTabClick,
    handleSummaryTabClick,
  } = useTabs();

  const hideSidebar = () => {
    hidePopup();
    onArrangementRequestClose();
  };

  const [
    unmountComponent,
    handleAnimationEnd,
    isUnmounting,
  ] = useUnmountAnimation(hideSidebar);

  const sendArrangementCancelRequest = () => {
    api.post(`/arrangements/${arrangementData.id}/cancel`, null, {
      headers: {
        Authorization: token,
      },
    })
      .then(() => {
        unmountComponent();
        onArrangementCanceled();
      })
      .catch((err) => {
        if (err.response?.data?.status === ApiError.UNAUTHORIZED) {
          dispatch(logout());
        } else {
          console.error(err);
        }
      });
  };

  return (
    <div className={classNames('arrangement-sidebar', isUnmounting && 'arrangement-sidebar_hide')}>
      <div className='arrangement-sidebar__shadow' onClick={unmountComponent} onAnimationEnd={handleAnimationEnd}/>
      <div className='arrangement-sidebar__content'>
        <CustomScrollbar width="100%" height="100%">
          <div className='arrangement-sidebar__content-wrapper'>
            <div className='arrangement-sidebar__top'>
              <div className="arrangement-sidebar__header">
                <Button
                  icon={<IconCancel />}
                  onClick={unmountComponent}
                />
                <h2 className='arrangement-sidebar__title'>Arrangement Details</h2>
                <Status
                  className='arrangement-sidebar__status status_sidebar'
                  status={arrangementData?.status || ''}
                />
              </div>
              <Tabs
                className='arrangement-sidebar__tabs'
                activeTab={activeTab}
                handleDocumentsTabClick={handleDocumentsTabClick}
                handleSummaryTabClick={handleSummaryTabClick}
              />
            </div>
            {activeTab === ArrangementTab.SUMMARY && arrangementData && (
              <ArrangementSidebarDataSummary arrangementData={arrangementData} showPopup={showPopup}/>
            )}
            {activeTab === ArrangementTab.DOCUMENTS && arrangementData && (
              <ArrangementSidebarDataDocuments documents={arrangementData.documents} />
            )}
          </div>
        </CustomScrollbar>
      </div>
      {isPopupShown && (
        <PopupCancelArrangement
          closePopup={hidePopup}
          handleSubmitButtonClick={sendArrangementCancelRequest}
        />
      )}
    </div>
  );
};

ArrangementSidebar.defaultProps = {
  arrangementData: null,
};

ArrangementSidebar.propTypes = {
  arrangementData: PropTypes.object,
  onArrangementRequestClose: PropTypes.func.isRequired,
  onArrangementCanceled: PropTypes.func.isRequired,
};

export default ArrangementSidebar;
