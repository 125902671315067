import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Button,
  Chip,
  Input,
  Select,
} from '../../../ui-components';

import useWindowScrollBlock from '../../../../hooks/useWindowScrollBlock';
import useFilters from '../../hooks/useFilters';
import { DATE_FILTERS, Mask } from '../../../../constants';

import './table-filters.scss';

const TableFilters = ({
  className,
  closeMobileFilters,
  isMobileFiltersMenuShown,
}) => {
  useWindowScrollBlock(isMobileFiltersMenuShown);
  const {
    activeFilter,
    applyFilters,
    deliveryDatePeriod,
    handleQuickFilterClick,
    setFromDate,
    setToDate,
    handleStatusSelect,
    handlePaymentStatusSelect,
    paymentStatusSelectList,
    statusSelectList,
  } = useFilters();

  return (
    <div className={classNames('table-filters', className, isMobileFiltersMenuShown && 'table-filters_shown-mobile')} >
      <div className='table-filters__wrapper'>
        <div className='table-filters__section'>
          <p className='table-filters__section-title'>Date of Arrangement</p>
          <div className='table-filters__section-filters-wrapper'>
            <div className='table-filters__section-inputs'>
              <Input
                className='table-filters__section-input'
                id='dateOfArrangementFrom'
                label='from'
                value={deliveryDatePeriod.from}
                mask={Mask.DATE_MASK}
                onChange={setFromDate}
                placeholder='MM/DD/YYYY'
              />
              <Input
                className='table-filters__section-input'
                id='dateOfArrangementTo'
                label='to'
                mask={Mask.DATE_MASK}
                value={deliveryDatePeriod.to}
                onChange={setToDate}
                placeholder='MM/DD/YYYY'
              />
            </div>
            <div className='table-filters__quick-date'>
              {DATE_FILTERS.map((filter) => (
                <Chip
                  key={filter}
                  isActive={activeFilter === filter}
                  value={filter}
                  onClick={() => handleQuickFilterClick(filter)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='table-filters__section'>
          <p className='table-filters__section-title'>Arrangement Status</p>
          <div className='table-filters__section-filters-wrapper table-filters__section-filters-wrapper_selects'>
            <Select
              id='tableFiltersPaymentStatus'
              defaultValue={paymentStatusSelectList[0]}
              label='Payment Status'
              listItems={paymentStatusSelectList}
              handleValueSet={(value) => handlePaymentStatusSelect(value)}
            />
            <Select
              id='tableFiltersStatus'
              defaultValue={statusSelectList[0]}
              label='Cremation Status'
              listItems={statusSelectList}
              handleValueSet={(value) => handleStatusSelect(value)}
            />
          </div>
        </div>
        <Button
          classNameCustom='table-filters__apply-button button_rectangle button_bg'
          onClick={() => {
            applyFilters();
            closeMobileFilters();
          }}
          text='Apply'
        />
      </div>
      {isMobileFiltersMenuShown && <div className='table-filters__mobile-shadow' onClick={closeMobileFilters}/>}
    </div>
  );
};

TableFilters.defaultProps = {
  className: '',
  isMobileFiltersMenuShown: false,
};

TableFilters.propTypes = {
  className: PropTypes.string,
  closeMobileFilters: PropTypes.func.isRequired,
  isMobileFiltersMenuShown: PropTypes.bool,
};

export default TableFilters;
