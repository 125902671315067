import React from 'react';
import classNames from 'classnames';

import { Button, Input } from '../../../ui-components';
import { Error, Loader } from '../../../common-components';

import usePhoneVerification from '../../hooks/usePhoneVerification';

import './verify-phone-screen.scss';

const VerifyPhoneScreen = ({ phone }) => {
  const formattedPhone = phone.replace('+1', '').replace(/[^0-9]+/g, '');

  const {
    code,
    error,
    handleCodeChange,
    handleVerifyLaterButtonClick,
    loading,
    loaderStatus,
  } = usePhoneVerification(formattedPhone);

  if (loading) {
    return <Loader status={loaderStatus}/>;
  }

  return (
    <div className='verify-phone-screen'>
      <div className='verify-phone-screen__content'>
        <p className='verify-phone-screen__text'>
          Please check your mobile phone for verification code
        </p>
        <p className='verify-phone-screen__text  verify-phone-screen__text_small'>
          You will soon receive a text message containing a 4 digit number
        </p>
        <Input
          autoFocus
          id='verificationCode'
          className='verify-phone-screen__input'
          disabled={loading}
          label="Enter code"
          mask="9999"
          maskPlaceholder="-"
          onChange={handleCodeChange}
          value={code}
        />
      </div>
      {error && <Error className='error_bg' text={error}/>}
      <Button
        classNameCustom={classNames('verify-phone-screen__link', loading && 'disabled')}
        text='Verify phone number later'
        onClick={handleVerifyLaterButtonClick}
      />
    </div>
  );
};
export default VerifyPhoneScreen;
