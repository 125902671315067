import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './status.scss';

const Status = ({
  className,
  status,
}) => (
  <div className={classNames('status', className, `status_${status.split(' ')[0].toLowerCase()}`)}>
    {status}
  </div>
);

Status.defaultProps = {
  className: '',
};

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
};

export default Status;
