import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './error.scss';

const Error = ({ className, text }) => (
  <div className={classNames('error', className)}>{text}</div>
);

Error.defaultProps = {
  className: '',
  text: '',
};

Error.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Error;
