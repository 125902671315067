import {useState} from 'react';
import { ArrangementTab } from '../constants';

export default function useTabs() {
  const [activeTab, setActiveTab] = useState(ArrangementTab.SUMMARY);

  const handleDocumentsTabClick = () => {
    setActiveTab(ArrangementTab.DOCUMENTS);
  };

  const handleSummaryTabClick = () => {
    setActiveTab(ArrangementTab.SUMMARY);
  };

  return {
    activeTab,
    handleDocumentsTabClick,
    handleSummaryTabClick,
  };
}
