import React, { useState } from 'react';
import classNames from 'classnames';

import {ReactComponent as IconFilters} from '../../../../assets/icons/icon-filters.svg';
import {ReactComponent as IconCancel} from '../../../../assets/icons/icon-cancel.svg';
import { Button } from '../../../ui-components';
import { CemeteryLogo, ExternalLink } from '../../../common-components';
import Signout from '../signout';
import TableFilters from '../table-filters';
import { IS_LOGO_TEXT_SEPARATED, LogoLocation } from '../../../../constants';

import './table-sidebar.scss';

const TableSidebar = () => {
  const [isMobileFiltersMenuShown, setIsMobileFiltersMenuShown] = useState(false);
  const [imgDimensions, setImgDimensions] = useState(null);
  const isImgSquare = imgDimensions && imgDimensions.height === imgDimensions.width;
  const isBiggerLogo = imgDimensions && imgDimensions.height !== imgDimensions.width && imgDimensions.height >= 80;

  const toggleFiltersMenu = () => {
    setIsMobileFiltersMenuShown((prevState) => !prevState);
  };

  const onImgLoad = ({target: img}) => {
    setImgDimensions({height: img.offsetHeight, width: img.offsetWidth});
  };

  return (
    <div className='table-sidebar'>
      <div className='table-sidebar__header'>
        <div className={classNames('table-sidebar__header-wrapper', isBiggerLogo && 'table-sidebar__header-wrapper_bigger-logo', (isImgSquare && !IS_LOGO_TEXT_SEPARATED) && 'table-sidebar__header-wrapper_square-spaced')}>
          <Signout
            className='table-sidebar__signout'
          />
          <CemeteryLogo imgClasses={(isImgSquare && !IS_LOGO_TEXT_SEPARATED) ? 'cemetery-logo__img_square' : ''} location={LogoLocation.SIDEBAR} onLoad={onImgLoad} />
          <Button
            classNameCustom='table-sidebar__header-filters-toggle'
            ariaLabel='Show filters'
            icon={isMobileFiltersMenuShown ? <IconCancel /> : <IconFilters />}
            onClick={toggleFiltersMenu}
          />
        </div>
      </div>
      <div className='table-sidebar__link-wrapper'>
        <ExternalLink
          className='table-sidebar__new-arrangement-button button button_rectangle button_bg'
          target='_self'
          text='New Arrangement'
          to={window.ARRANGEMENT_FORM_URL || '/'}
        />
      </div>
      <TableFilters
        className='table-sidebar__filters'
        closeMobileFilters={() => setIsMobileFiltersMenuShown(false)}
        isMobileFiltersMenuShown={isMobileFiltersMenuShown}
      />

    </div>
  );
};

export default TableSidebar;
