import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Route,
  Switch,
} from 'react-router-dom';

import ArrangementsPage from '../arrangements-page';
import MainPage from '../main-page';
import PolicyPage from '../policy-page';
import TermsPage from '../terms-page';

import {AppRoute} from '../../constants';
import useVhValue from '../../hooks/useVhValue';
import { loadFuneralHomesData } from '../../store/funeralHomesSlice';

const App = () => {
  const dispatch = useDispatch();

  useVhValue();

  useEffect(() => {
    dispatch(loadFuneralHomesData());
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={AppRoute.ROOT}
        component={MainPage}
      />
      <Route
        exact
        path={AppRoute.ARRANGEMENTS}
        component={ArrangementsPage}
      />
      <Route
        exact
        path={AppRoute.TERMS}
        component={TermsPage}
      />
      <Route
        exact
        path={AppRoute.POLICY}
        component={PolicyPage}
      />
    </Switch>
  );
};

export default App;
