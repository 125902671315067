import { configureStore } from '@reduxjs/toolkit';
import arrangementsReducer from './arrangementsSlice';
import authorizationReducer from './authorizationSlice';
import funeralHomesReducer from './funeralHomesSlice';

const store = configureStore({
  reducer: {
    arrangements: arrangementsReducer,
    authorization: authorizationReducer,
    funeralHomes: funeralHomesReducer,
  },
});

export default store;
