import baseAxios from 'axios';

const api = baseAxios.create({
  baseURL: window.API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default api;
