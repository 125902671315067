import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({
  children,
  className,
  target,
  text,
  to,
  ...props
}) => (
  <a
    className={className}
    href={to}
    target={target}
    rel='noopener noreferrer'
    {...props}
  >
    {text}
    {children}
  </a>
);

ExternalLink.defaultProps = {
  className: '',
  target: '_blank',
  text: '',
};

ExternalLink.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ExternalLink;
