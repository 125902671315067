import React from 'react';
import PropTypes from 'prop-types';

import Radio from '../radio';

const RadioList = ({
  className,
  disabled,
  listItems,
  onChange,
  selectedItem,
}) => (
  <div className={className}>
    {
      listItems.map((item) => (
        <Radio
          checked={selectedItem && selectedItem.id === item.id}
          id={`multiselectOption-${item.id}`}
          disabled={disabled}
          key={item.value}
          label={item.value}
          onChange={() => onChange(item)}
        />
      ))
    }
  </div>
);

RadioList.defaultProps = {
  className: '',
  disabled: false,
  listItems: [],
  onChange: () => {},
  selectedItem: null,
};

RadioList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
  })),
  onChange: PropTypes.func,
  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
  }),
};

export default RadioList;
