import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {CemeteryLogo, Error} from '../../../common-components';

import { LogoLocation } from '../../../../constants';

import './module-header.scss';

const ModuleHeader = ({
  text,
  title,
  error,
}) => (
  <div className={classNames('module-header', text && 'module-header_with-text')}>
    <div className='module-header__wrapper'>
      <CemeteryLogo location={LogoLocation.LOGIN} />
      <h1 className='module-header__title'>{title}</h1>
    </div>
    {text && <p className='module-header__text'>{text}</p>}
    {error && <Error text={error}/>}
  </div>
);

ModuleHeader.defaultProps = {
  error: '',
  text: '',
  title: '',
};

ModuleHeader.propTypes = {
  error: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default ModuleHeader;
