import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../buttons';
import Input from './input';
import {ReactComponent as IconShow} from '../../../assets/icons/icon-show.svg';

import './input.scss';

const InputPasswordIcon = ({ className, ...props }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  return (
    <Input
      className={classNames('input_with-icon', className)}
      type={isPasswordHidden ? 'password' : 'text'}
      {...props}
    >
      <Button
        classNameCustom="input__icon-button"
        icon={<IconShow />}
        onClick={() => setIsPasswordHidden((prevState) => !prevState)}
      />
    </Input>
  );
};

InputPasswordIcon.defaultProps = {
  className: '',
};

InputPasswordIcon.propTypes = {
  className: PropTypes.string,
};

export default InputPasswordIcon;
