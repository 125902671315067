import React from 'react';
import PropTypes from 'prop-types';

import './radio.scss';

const Radio = ({
  checked,
  children,
  className,
  disabled,
  id,
  invalid,
  label,
  onChange,
}) => (
  <label htmlFor={id} className={`radio ${invalid ? 'radio_error' : ''} ${disabled ? 'radio_disabled' : ''} ${className}`}>
    {label && <span className='radio__label'>{label}</span>}
    <input
      className='radio__input'
      type='radio'
      id={id}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span className='radio__control' onClick={onChange}/>
    {children}
  </label>
);

Radio.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  invalid: false,
  label: '',
  onChange: () => {},
};

Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default Radio;
