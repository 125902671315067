import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Input} from '../input';
import TypeaheadDropdown from './components/typeahead-dropdown';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useKeyDown from '../../../hooks/useKeyDown';
import { KeyCode } from '../../../constants';

import './typeahead.scss';

const MAX_HINTS_COUNT = 5;

const Typeahead = ({
  className,
  defaultValue,
  disabled,
  handleValueSet,
  id,
  isFlattened,
  label,
  listItems,
  listLoading,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [filteredList, setFilteredList] = useState([]);
  const [searchStr, setSearchStr] = useState(defaultValue?.value || '');
  const [isTyping, setIsTyping] = useState(false);
  const inputRef = useRef(null);
  const isDropdownShown = filteredList.length > 0 && isTyping;

  const clearSearchStr = useCallback(() => setSearchStr(''), []);

  const closeMenu = () => {
    setIsTyping(false);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
      handleValueSet(defaultValue);
      setSearchStr(defaultValue?.value);
    }
  }, [defaultValue, listItems]);

  useEffect(() => {
    if (!isTyping && listItems.length > 0) {
      if ((!selectedOption && searchStr) || (selectedOption && selectedOption?.value !== searchStr)) {
        const appropriateItem = listItems.find((it) => it.value.toLocaleLowerCase() === searchStr.toLocaleLowerCase());
        if (appropriateItem) {
          setSelectedOption(appropriateItem);
          handleValueSet(appropriateItem);
          setSearchStr(appropriateItem.value);
        } else {
          clearSearchStr();
          setSelectedOption(null);
          handleValueSet(null);
        }
      }
    }
  }, [isTyping, listItems, searchStr, selectedOption]);

  useEffect(() => {
    const filterList = () => {
      const newList = [...listItems.filter((item) => searchStr.length > 0 && item.value.toLowerCase().includes(searchStr.toLowerCase()))].slice(0, MAX_HINTS_COUNT);
      setFilteredList(newList);
    };
    filterList();
  }, [searchStr, listItems]);

  useOnClickOutside(inputRef, closeMenu);
  useKeyDown(KeyCode.ESCAPE, closeMenu, null, !isTyping);

  /**
   * Sets selected option to the state and triggers handleValueSet callback
   * @param  {object} item {selected option}
   */
  const setValue = useCallback((item) => {
    setSelectedOption(item);
    setSearchStr(item.value);
    handleValueSet(item);
    closeMenu();
  }, []);

  const handleSearchStrChange = useCallback(({ target: { value } }) => {
    setSearchStr(value);
  }, []);

  const handleInputBlur = () => {
    if (!filteredList.length > 0) {
      closeMenu();
    }
  };

  const handleInputFocus = () => setIsTyping(true);

  return (
    <Input
      className={classNames('typeahead', disabled && 'typeahead_disabled', className)}
      id={id}
      isFlattened={isFlattened}
      disabled={disabled}
      label={label}
      onBlur={handleInputBlur}
      onChange={handleSearchStrChange}
      onFocus={handleInputFocus}
      ref={inputRef}
      value={searchStr}
    >
      {
        isDropdownShown && (
          <TypeaheadDropdown
            handleItemClick={setValue}
            listItems={filteredList}
            listLoading={listLoading}
            searchStr={searchStr}
          />
        )
      }
    </Input>
  );
};

Typeahead.defaultProps = {
  className: '',
  defaultValue: null,
  handleValueSet: () => {},
  disabled: false,
  listItems: [],
  listLoading: false,
};

Typeahead.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  handleValueSet: PropTypes.func,
  disabled: PropTypes.bool,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  listLoading: PropTypes.bool,
};

export default Typeahead;
