import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CREMATION_STATUSES,
  DateFilter,
  PAYMENT_STATUSES,
  Regex,
  DAY_IN_MS,
} from '../../../constants';
import { formatDateToUSLocal, getDefaultTodayDate } from '../../../utils';
import { setDateFilters, setPaymentStatusFilter, setStatusFilter } from '../../../store/arrangementsSlice';

const LAPTOP_WIDTH = 1024;

const statusSelectList = [{id: 'cremationStatusAll', value: 'All', apiValue: ''}, ...CREMATION_STATUSES];
const paymentStatusSelectList = [{id: 'paymentStatusAll', value: 'All', apiValue: ''}, ...PAYMENT_STATUSES];

export default function useFilters() {
  const {dateFilters} = useSelector((state) => state.arrangements);
  const dispatch = useDispatch();
  const [deliveryDatePeriod, setDeliveryDatePeriod] = useState({from: '', to: ''});
  const [paymentStatus, setPaymentStatus] = useState('');
  const [status, setStatus] = useState('');
  const [activeFilter, setActiveFilter] = useState(DateFilter.DEFAULT);

  const onTodayFilterClick = () => {
    setDeliveryDatePeriod({
      from: formatDateToUSLocal(getDefaultTodayDate()),
      to: formatDateToUSLocal(getDefaultTodayDate()),
    });
  };

  const onThreeDaysFilterClick = () => {
    setDeliveryDatePeriod({
      from: formatDateToUSLocal(getDefaultTodayDate()),
      to: formatDateToUSLocal(getDefaultTodayDate().getTime() + DAY_IN_MS * 2),
    });
  };

  const onWeekFilterClick = () => {
    setDeliveryDatePeriod({
      from: formatDateToUSLocal(getDefaultTodayDate()),
      to: formatDateToUSLocal(getDefaultTodayDate().getTime() + DAY_IN_MS * 6),
    });
  };

  const handleQuickFilterClick = (filter) => {
    switch (filter) {
      case DateFilter.TODAY:
        onTodayFilterClick();
        break;
      case DateFilter.THREE_DAYS:
        onThreeDaysFilterClick();
        break;
      case DateFilter.WEEK:
        onWeekFilterClick();
        break;
      default:
        break;
    }
  };

  const sendDateFiltersValues = () => {
    if ((Regex.DATE.test(deliveryDatePeriod.from) && deliveryDatePeriod.from === dateFilters.from)
      && (Regex.DATE.test(deliveryDatePeriod.to) && deliveryDatePeriod.to === dateFilters.to)
      || (Regex.DATE.test(deliveryDatePeriod.from) && deliveryDatePeriod.from === dateFilters.from) && (deliveryDatePeriod.to && !Regex.DATE.test(deliveryDatePeriod.to))
      || (Regex.DATE.test(deliveryDatePeriod.to) && deliveryDatePeriod.to === dateFilters.to) && (deliveryDatePeriod.from && !Regex.DATE.test(deliveryDatePeriod.from))) {
      return;
    }

    const requestFilters = {};
    if (Regex.DATE.test(deliveryDatePeriod.from) || (deliveryDatePeriod.from === '' && dateFilters.from)) {
      requestFilters.from = deliveryDatePeriod.from;
    }
    if (Regex.DATE.test(deliveryDatePeriod.to) || (deliveryDatePeriod.to === '' && dateFilters.to)) {
      requestFilters.to = deliveryDatePeriod.to;
    }

    if ('from' in requestFilters || 'to' in requestFilters) {
      dispatch(setDateFilters(requestFilters));
    }
  };

  const applyFilters = () => {
    sendDateFiltersValues();
    dispatch(setStatusFilter(status));
    dispatch(setPaymentStatusFilter(paymentStatus));
  };

  const resetFilters = () => {
    dispatch(setDateFilters({from: '', to: ''}));
    dispatch(setPaymentStatusFilter(''));
    dispatch(setStatusFilter(''));
  };

  const setFromDate = ({target: {value}}) => {
    setDeliveryDatePeriod((prevPeriod) => ({
      ...prevPeriod,
      from: value,
    }));
  };

  const setToDate = ({target: {value}}) => {
    setDeliveryDatePeriod((prevPeriod) => ({
      ...prevPeriod,
      to: value,
    }));
  };

  const checkActiveFilter = () => {
    let newActiveFilter = DateFilter.DEFAULT;

    if (deliveryDatePeriod.from === formatDateToUSLocal(getDefaultTodayDate())
      && deliveryDatePeriod.to === formatDateToUSLocal(getDefaultTodayDate())) {
      newActiveFilter = DateFilter.TODAY;
    }

    if (deliveryDatePeriod.from === formatDateToUSLocal(getDefaultTodayDate())
      && deliveryDatePeriod.to === formatDateToUSLocal(getDefaultTodayDate().getTime() + DAY_IN_MS * 2)) {
      newActiveFilter = DateFilter.THREE_DAYS;
    }

    if (deliveryDatePeriod.from === formatDateToUSLocal(getDefaultTodayDate())
      && deliveryDatePeriod.to === formatDateToUSLocal(getDefaultTodayDate().getTime() + DAY_IN_MS * 6)) {
      newActiveFilter = DateFilter.WEEK;
    }

    setActiveFilter(newActiveFilter);
  };

  const handleStatusSelect = (selectValue) => {
    setStatus(selectValue.apiValue);
  };

  const handlePaymentStatusSelect = (selectValue) => {
    setPaymentStatus(selectValue.apiValue);
  };

  useEffect(() => {
    checkActiveFilter();
    if (window.innerWidth < LAPTOP_WIDTH) {
      return;
    }
    sendDateFiltersValues();
  }, [deliveryDatePeriod]);

  useEffect(() => {
    if (window.innerWidth < LAPTOP_WIDTH) {
      return;
    }
    dispatch(setStatusFilter(status));
  }, [status]);

  useEffect(() => {
    if (window.innerWidth < LAPTOP_WIDTH) {
      return;
    }
    dispatch(setPaymentStatusFilter(paymentStatus));
  }, [paymentStatus]);

  useEffect(() => () => resetFilters(), []);

  return {
    activeFilter,
    applyFilters,
    deliveryDatePeriod,
    handleQuickFilterClick,
    setFromDate,
    setToDate,
    handleStatusSelect,
    handlePaymentStatusSelect,
    paymentStatusSelectList,
    statusSelectList,
  };
}
