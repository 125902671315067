import {useEffect, useRef, useState} from 'react';

export default function useIntersect(disconnectParam) {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  useEffect(() => () => setNode(null), []);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([newEntry]) => updateEntry(newEntry), {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    const {current: currentObserver} = observer;
    if (node) {
      return disconnectParam ? currentObserver.disconnect() : currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [node, disconnectParam]);

  return [setNode, entry];
}
