import React from 'react';

import ExternalLink from '../../../common-components/external-link';
import {ReactComponent as IconAfs} from '../../../../assets/icons/logo-afs.svg';

import './copyright.scss';

const Copyright = () => (
  <div className='copyright'>
    <ExternalLink
      to='https://allfuneral.com/'
    >
      <IconAfs className='copyright__icon'/>
    </ExternalLink>
  </div>
);

export default Copyright;
