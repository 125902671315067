import React, { useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Button} from '../../../ui-components';
import Popup from '../popup';

import useKeyDown from '../../../../hooks/useKeyDown';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useWindowScrollBlock from '../../../../hooks/useWindowScrollBlock';
import useUnmountAnimation from '../../../../hooks/useUnmountAnimation';
import { KeyCode } from '../../../../constants';

const PopupCancelArrangement = ({
  handleSubmitButtonClick,
  text,
  closePopup,
}) => {
  const popupRef = useRef(null);
  const [
    unmountComponent,
    handleAnimationEnd,
    isUnmounting,
  ] = useUnmountAnimation(closePopup);
  useOnClickOutside(popupRef, unmountComponent);
  useKeyDown(KeyCode.ESCAPE, unmountComponent);
  useWindowScrollBlock(true);

  return (
    <Popup className={classNames('popup_cancel', isUnmounting && 'popup_hiding')}>
      <div className='popup__wrapper' ref={popupRef} onAnimationEnd={handleAnimationEnd}>
        <div className='popup__texts'>
          <p className='popup__text'>Cancel this Arrangement?</p>
          <p className='popup__text'>{text}</p>
        </div>
        <div className='popup__buttons'>
          <Button
            classNameCustom='button_rectangle  button_bg  popup__button'
            text='Cancel Arrangement'
            onClick={() => {
              closePopup();
              handleSubmitButtonClick();
            }}
          />
          <Button
            classNameCustom='button_rectangle  popup__button'
            text="Don't cancel"
            onClick={unmountComponent}
          />
        </div>
      </div>
    </Popup>
  );
};

PopupCancelArrangement.defaultProps = {
  text: '',
};

PopupCancelArrangement.propTypes = {
  closePopup: PropTypes.func.isRequired,
  handleSubmitButtonClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default PopupCancelArrangement;
